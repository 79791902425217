import { Button } from "@mui/material";
import Demand from "../../../model/database/Demand";
import CustomDialog from "../../common/CustomDialog";
import InterestedUser from "../../common/InterestedUser";

interface DemandInterestedModalProps {
    open: boolean,
    demand?: Demand,
    handleClose: () => void
}

const DemandInterestedModal = ({open, demand, handleClose}: DemandInterestedModalProps) => {

    const resetAndClose = () => {
        handleClose();
    }

    return <CustomDialog
        open={open ? open : false}
        handleClose={resetAndClose}
        title="Utenti interessati al fabbisogno"
        content={
            <>
                {demand?.userLikes?.filter(like => like.state === 'INTERESTED').map(like => <InterestedUser
                    key={like.user?.id}
                    userInfo={like} />)}
            </>
        }
        actions={
            <>
                <Button color="inherit" onClick={resetAndClose}>
                    Chiudi
                </Button>
            </>
        }
    />;
}

export default DemandInterestedModal;
