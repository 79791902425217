import { LabelValue } from "../model/filters/LabelValue";

const productTypes: LabelValue[] = [
    {
        label: 'Prodotti',
        value: 'PRODUCT',
    },
    {
        label: 'Eventi',
        value: 'SERVICE',
    },
    {
        label: 'Sfide',
        value: 'CHALLENGE',
    },
    {
        label: 'Bandi',
        value: 'CALL',
    }
];

export default productTypes;