import { Button, Grid, MenuItem, TextField, Theme, useTheme } from "@mui/material";
import { HttpStatusCode } from "axios";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import researchServiceTypes from "../../../constants/researchServiceTypes";
import LoggedUser from "../../../model/auth/LoggedUser";
import Keyword from "../../../model/database/Keyword";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchService from "../../../model/database/ResearchService";
import FileDTO from "../../../model/dto/FileDTO";
import ResearchServiceDTO from "../../../model/dto/ResearchServiceDTO";
import AuthContext from "../../../route/AuthContext";
import ProductService from "../../../services/ProductService";
import RegexUtil from "../../../utils/RegexUtil";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import UploadDocumentField from "../../common/UploadDocumentField";
import KeywordsAutocompleteFreesolo from "../../common/select/KeywordsAutocompleteFreesolo";
import ProducersAutocomplete from "../../common/select/ProducersAutocomplete";

interface ServiceModalProps {
    open: boolean,
    edit: boolean,
    researchService?: ResearchService,
    loggedUser?: LoggedUser,
    keywords: Keyword[],
    producers: ResearchProducer[],
    handleClose: () => void,
    handleSave: (serviceToSave: ResearchServiceDTO) => Promise<boolean>
}

function getStyles(id: string, ids: string[], theme: Theme) {
    return {
        fontWeight:
            ids.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ServiceModal = ({
                          open,
                          edit,
                          loggedUser,
                          researchService,
                          keywords,
                          producers,
                          handleSave,
                          handleClose
                      }: ServiceModalProps) => {

    const authContext = React.useContext(AuthContext);
    const formikRef = React.useRef<any>(undefined);

    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [document, setDocument] = React.useState<FileDTO | null>(null);
    const [cleanFile, setCleanFile] = React.useState<boolean>(false);
    const [checkFileSize, setCheckFileSize] = React.useState<boolean>(true);
    const [title, setTitle] = React.useState<string>('Crea nuovo evento');

    React.useEffect(() => {
        if (!open)
            resetAndClose();
    }, [open]);

    React.useEffect(() => {
        setEditMode(edit);
    }, [edit]);

    React.useEffect(() => {
        const loadDocument = async () => {
            if (researchService && researchService.product?.id  && document === null) {
                const [status, response] = await ProductService.getDocumentResearchServiceById(authContext, researchService.product?.id ?? '');
                if (status && status === HttpStatusCode.Ok)
                    setDocument(response);
            }
        }
        loadDocument();
    }, [researchService]);

    React.useEffect(() => {
        if (editMode && researchService){
            if(researchService.product?.id)
                setTitle('Modifica evento');
            else
                setTitle('Crea nuovo evento');
        }else if (researchService)
            setTitle('Dettagli evento');
        else
            setTitle('Crea nuovo evento');
    }, [editMode, researchService]);

    const resetAndClose = () => {
        setEditMode(false);
        setCleanFile(true);
        setDocument(null);
        if (formikRef.current)
            formikRef.current.resetForm();
        handleClose();
    }

    const theme = useTheme();

    return <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
            name: researchService ? researchService.product?.name : '',
            description: researchService ? researchService.product?.description : '',
            startDate: researchService ? researchService.product?.startDate : '',
            endDate: researchService ? researchService.product?.endDate : '',
            user: researchService ? researchService.producer?.user?.id : (loggedUser?.role === 'RESEARCH_PRODUCER' ? loggedUser.id : ''),
            keywords: researchService ? researchService.product?.keywords?.map(value => value.id) : [],
            suggested: [],
            address: researchService ? researchService.address : '',
            website: researchService ? researchService.website : '',
            type: researchService ? researchService.type : '',
            document: researchService && document ? document : null
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il nome è obbligatorio e non può essere vuoto.'),
            description: Yup.string().max(8192, 'Il valore massimo consentito è di 8192 caratteri')
                .required('La descrizione è obbligatorio e non può essere vuoto.'),
            user: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il produttore è obbligatorio e non può essere vuoto.'),
            keywords: Yup.array().of(Yup.string()).min(1, 'Almeno 1 parola chiave deve essere selezionata')
                .max(10, 'Si possono selezionare al massimo 10 parole chiave')
                .required('Le parole chiave sono obbligatorie e non possono essere vuote.'),
            type: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia è obbligatoria e non può essere vuota.'),
            startDate: Yup.date()
                .max(Yup.ref('endDate'), 'La data di inizio deve essere minore della data di fine')
                .required('La data di inizio è obbligatorio e non può essere vuoto.'),
            endDate: Yup.date()
                .min(Yup.ref('startDate'), 'La data di fine deve essere maggiore della data di inizio')
                .required('La data di fine è obbligatoria e non può essere vuota.'),
            website: Yup.string().max(1024, 'Il valore massimo consentito è di 1024 caratteri')
                .matches(RegexUtil.webSiteRegex,
                    'Sito web non valido'),
            address: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il luogo è obbligatorio e non può essere vuoto.'),
        })}
        onSubmit={async (values, {resetForm}) => {
            let result = await handleSave({
                ...values as ResearchServiceDTO,
                suggested: values.suggested.filter((v: any) => values.keywords?.includes(v.id)).map((v: any) => ({
                    name: v.name,
                    state: v.state,
                    description: '',
                    sdgs: []
                }))
            });
            if (result) {
                resetForm();
                setEditMode(false);
            }
        }}
    >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              submitForm,
              setFieldValue,
              values
          }) => (
            <CustomDialog
                open={open ? open : false}
                handleClose={() => {
                    if (!isSubmitting) resetAndClose()
                }}
                title={title}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            {loggedUser && (loggedUser?.role !== 'RESEARCH_PRODUCER' || (researchService && loggedUser.id !== researchService?.producer?.id)) &&
                                <ResponsiveGridItem>
                                    <ProducersAutocomplete producers={producers} fieldName="user"
                                                           readOnly={!editMode && researchService !== undefined}
                                                           showMail={loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT'}
                                                           label="Produttore proponente"/>
                                </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label="Nome evento"
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && researchService !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <KeywordsAutocompleteFreesolo
                                    keywords={researchService ? [...keywords, ...researchService.product?.keywords?.filter(k => k.state === 'INACTIVE') ?? []] : keywords}
                                    fieldName="keywords"
                                    suggestedFieldName="suggested"
                                    readOnly={!editMode && researchService !== undefined}
                                    label="Parole chiave"/>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.description && errors.description)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText={(touched.description && errors.description) as React.ReactNode}
                                    label="Descrizione"
                                    margin="normal"
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.description}
                                    required
                                    variant="outlined"
                                    inputProps={{
                                        readOnly: !editMode && researchService !== undefined
                                    }}
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={4}>
                                <TextField
                                    error={Boolean(touched.type && errors.type)}
                                    fullWidth
                                    helperText={touched.type && errors.type}
                                    label="Tipo di evento"
                                    margin="normal"
                                    name="type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode && researchService !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.type}
                                    required
                                    variant="outlined"
                                >
                                    {researchServiceTypes.map(value => <MenuItem key={value.value}
                                                                                 value={value.value}>{value.label}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={4}>
                                <TextField
                                    error={Boolean(touched.startDate && errors.startDate)}
                                    fullWidth
                                    helperText={touched.startDate && errors.startDate}
                                    label="Data di inizio"
                                    margin="normal"
                                    name="startDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && researchService !== undefined,
                                        max: values.endDate
                                    }}
                                    type="date"
                                    disabled={isSubmitting}
                                    value={values.startDate}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={4}>
                                <TextField
                                    error={Boolean(touched.endDate && errors.endDate)}
                                    fullWidth
                                    helperText={touched.endDate && errors.endDate}
                                    label="Data di fine"
                                    margin="normal"
                                    name="endDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && researchService !== undefined,
                                        min: values.startDate
                                    }}
                                    disabled={isSubmitting}
                                    value={values.endDate}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.address && errors.address)}
                                    fullWidth
                                    helperText={touched.address && errors.address}
                                    label="Luogo"
                                    margin="normal"
                                    name="address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && researchService !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.address}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <UploadDocumentField
                                    clean={cleanFile}
                                    propertyId="document"
                                    editMode={editMode || !researchService}
                                    checkSize={checkFileSize}
                                    fileTypes=".pdf"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.website && errors.website)}
                                    fullWidth
                                    helperText={touched.website && errors.website}
                                    label="Sito web"
                                    margin="normal"
                                    name="website"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && researchService !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.website}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                        </Grid>
                    </form>
                }
                actions={
                    <>
                        <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
                            Chiudi
                        </Button>
                        {loggedUser && (
                            (loggedUser.role === 'RESEARCH_PRODUCER' &&
                                (loggedUser.id === researchService?.producer?.id ||
                                    (loggedUser.idParent && loggedUser.idParent === researchService?.producer?.id)
                                )
                            ) || loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT'
                        ) && (!editMode && researchService !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                Modifica
                            </Button> :
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                Conferma
                                {isSubmitting && <CircularLoading/>}
                            </Button>)}
                        {loggedUser &&
                            (loggedUser.role === 'RESEARCH_PRODUCER') && (!researchService || !researchService.product?.id) && !editMode && !researchService &&
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                Conferma
                                {isSubmitting && <CircularLoading/>}
                            </Button>}
                    </>
                }
            />
        )}
    </Formik>;
}

export default ServiceModal;
