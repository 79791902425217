import { Backdrop, CircularProgress, Typography, useTheme } from "@mui/material";
import { HttpStatusCode } from "axios";
import React from "react";
import { Bar } from 'react-chartjs-2';
import AuthContext from "../../../route/AuthContext";
import StatisticService from '../../../services/StatisticService';
import Item from "../../common/Item";

export const options = {
    indexAxis: 'y' as const,
    responsive: true,
    scales: {
      x: {
        ticks: {
            stepSize: 1
        }
      }  
    },
    plugins: {
        legend: {
            display: false,
            position: 'right' as const,
        },
        title: {
            display: false
        }
    },
};

interface DemandsBarChartProps {
    topK: number,
    state: string,
    title: string,
    secondary: boolean
}

const DemandsBarChart = ({ title, topK, state, secondary }: DemandsBarChartProps) => {
    const authContext = React.useContext(AuthContext);
    const theme = useTheme();
    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState({
        labels: [] as any[],
        datasets: [] as any[]
    });

    const loadData = async () => {
        setLoading(true);
        let [status, response] = await StatisticService.getDemandsTopKByState(authContext, topK, state);
        if (status && response && status === HttpStatusCode.Ok) {
            const newData = {
                labels: response.map((value: any) => value.name),
                datasets: [{
                    data: response.map((value: any) => value.count),
                    backgroundColor: secondary ? theme.palette.secondary.main : theme.palette.primary.main
                }],
            }
            setData(newData);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        loadData();
    }, []);

    return <Item>
        <Backdrop
            sx={{ color: 'secondary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        <Typography variant="h6" gutterBottom align="left" mb={2}>{title}</Typography>
        <Bar options={options} data={{
            labels: data.labels,
            datasets: data.datasets
        }} />
    </Item>
}

export default DemandsBarChart;
