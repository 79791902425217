import { HttpStatusCode } from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import http from '../utils/HttpCommon';

const getPendent = async (context: LoggedUserContext) => {
    try {
        const response = await http.privateClient(context).get<any>('/stats/pendentEvents');

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const getDataForCharts = async (context: LoggedUserContext) => {
    try {
        const response = await http.privateClient(context).get<any>('/stats/dataForCharts');

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const getTotalPublic = async () => {
    try {
        const response = await http.publicClient.get<any>('/stats/totalProducts');

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const getDemandsTopKByState = async (context: LoggedUserContext, topK: number, state: string) => {
    try {
        const response = await http.privateClient(context).get<any>(`/stats/demands/top/${topK}/state/${state}`);

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const getDemandsCountByKeyword = async (context: LoggedUserContext) => {
    try {
        const response = await http.privateClient(context).get<any>(`/stats/demands/keywords`);

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const getDemandLikesPerYear = async (context: LoggedUserContext, year: number) => {
    try {
        const response = await http.privateClient(context).get<any>(`/stats/demandlikes/year/${year}`);

        if (response && response.status === HttpStatusCode.Ok) {
            return [response.status, response.data];
        }

        return [response ? response.status : undefined, undefined];

    } catch (e: any) {
        console.error(e);
        return [e.response ? e.response.status : undefined, undefined];
    }
}

const StatisticService = {
    getPendent,
    getDataForCharts,
    getTotalPublic,
    getDemandsTopKByState,
    getDemandsCountByKeyword,
    getDemandLikesPerYear
}

export default StatisticService;