import { Cancel, UploadFile } from '@mui/icons-material';
import { Button, Paper, Stack, Typography } from "@mui/material";
import { Buffer } from 'buffer';
import { useFormikContext } from "formik";
import * as React from 'react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import FileDTO from '../../model/dto/FileDTO';
import CustomSweetalert from "../../theme/sweetalert";

interface UploadDocumentFieldProps {
    propertyId: string,
    editMode?: boolean
    clean?: boolean,
    checkSize?: boolean,
    fileTypes?: string
}

const UploadDocumentField = ({clean, propertyId, editMode, checkSize, fileTypes}: UploadDocumentFieldProps) => {

    const {t} = useTranslation();

    const {isSubmitting, values, setFieldValue} = useFormikContext<any>();

    const [file, setFile] = React.useState<FileDTO | null>(values[propertyId]);

    const fileRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        setFile(values[propertyId]);
    }, [propertyId, values]);

    React.useEffect(() => {
        if (clean) {
            handleClean();
        }
    }, [clean]);

    const handleDownload = async () => {
        const b64 = file?.file ?? '';
        var b64Buffer = Buffer.from(b64.split(',')[1], 'base64');
        const b64Blob = new Blob([b64Buffer], {type: file?.type});
        const url = window.URL.createObjectURL(b64Blob);
        // const w = window.open('', "_blank");
        // w?.document.write(`<iframe src=${url} width='100%' height='100%'/>`);
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = url;
        a.download = file?.name ?? '';
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    const handleClean = () => {
        setFile(null);
        setFieldValue(propertyId, null);
        if (fileRef.current) {
            fileRef.current.value = '';
        }
    }

    const toFormats = (str = '.png,.jpg,.jpeg,.pdf,.doc,.docx') => {
        return str.split(",")
            .map(s => s.replaceAll(".", "").toUpperCase())
            .join(',')
    }

    const handleSelection = (e: ChangeEvent) => {
        const fileInput = e.target as HTMLInputElement;
        const maxSize = 1024 * 1024 * 3;
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            if (!checkSize || fileInput.files[0].size <= maxSize) {
                var fr = new FileReader();
                let fileDTO: FileDTO = {
                    name: fileInput.files[0].name,
                    type: fileInput.files[0].type,
                    file: ''
                }
                fr.onload = function (event) {
                    let newValues = {...values};
                    newValues.document = fr.result as string;
                    fileDTO.file = fr.result as string;
                    setFile(fileDTO);
                    setFieldValue(propertyId, fileDTO);
                }
                fr.readAsDataURL(fileInput.files[0]);
            } else {
                CustomSweetalert({
                    title: 'Attenzione',
                    text: 'Il file selezionato supera la dimensione massima consentita.',
                    icon: 'error'
                })
            }
        }
    }

    return <>
        <Stack direction={{md: "row", xs: "column", sm: "column"}} alignItems="stretch" spacing={1}>
            <Paper sx={{p: 2, flex: 1}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Stack direction="column" alignItems="start" justifyContent="space-between" spacing={1}>
                        <Typography whiteSpace="pre-line" gutterBottom variant="body1"
                                    mb={1}>{file !== null && file?.file !== null ? t('file.available') :
                            t('file.empty').replace("$$$FORMATS$$$", fileTypes ? toFormats(fileTypes) : toFormats())}</Typography>
                        {file !== null && file?.name !== null &&
                            <Typography gutterBottom variant="body1" mb={1}><strong>{file?.name}</strong></Typography>}
                    </Stack>
                    {file !== null && file?.file !== null &&
                        <Button disabled={isSubmitting} variant="contained" onClick={() => handleDownload()} autoFocus>
                            {t('file.download')}
                        </Button>}
                </Stack>
            </Paper>
            {editMode &&
                <Stack justifyContent="center">
                    <Button
                        component="label"
                        color="secondary"
                        variant="outlined"
                        disabled={isSubmitting}
                        startIcon={<UploadFile/>}
                        sx={{marginRight: "1rem"}}
                    >
                        {t('file.select')}
                        <input ref={fileRef} type="file" accept={fileTypes ?? '.png,.jpg,.jpeg,.pdf,.doc,.docx'} hidden
                               onChange={handleSelection}/>
                    </Button>
                    {file && <Button
                        color="inherit"
                        variant="outlined"
                        disabled={isSubmitting}
                        startIcon={<Cancel/>}
                        sx={{marginRight: "1rem"}}
                        onClick={handleClean}
                    >
                        Pulisci
                    </Button>}
                </Stack>
            }
        </Stack>
    </>
}

export default UploadDocumentField;
