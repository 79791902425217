import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import { HttpStatusCode } from "axios";
import { Chart, LineElement, PointElement } from "chart.js";
import React from "react";
import { Line } from 'react-chartjs-2';
import AuthContext from "../../../route/AuthContext";
import StatisticService from '../../../services/StatisticService';
import GeneratorUtil from "../../../utils/GeneratorUtil";
import Item from "../../common/Item";

Chart.register(PointElement, LineElement);

export const options = {
    responsive: true,
    scales: {
        y: {
            ticks: {
                stepSize: 1
            }
        }
    },
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false
        }
    },
};

interface DemandsLikePerYearProps {
    title: string
}

const months: any = {
    1: 'Gennaio',
    2: 'Febbraio',
    3: 'Marzo',
    4: 'Aprile',
    5: 'Maggio',
    6: 'Giugno',
    7: 'Luglio',
    8: 'Agosto',
    9: 'Settembre',
    10: 'Ottobre',
    11: 'Novembre',
    12: 'Dicembre'
}

const DemandsLikePerYear = ({ title }: DemandsLikePerYearProps) => {
    const authContext = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(true);
    const [year, setYear] = React.useState(new Date().getFullYear());
    const [data, setData] = React.useState({
        labels: [] as any[],
        datasets: [] as any[]
    });

    const loadData = async () => {
        setLoading(true);
        let [status, response] = await StatisticService.getDemandLikesPerYear(authContext, year);
        if (status && response && status === HttpStatusCode.Ok) {
            const groupedById = response.reduce((groups: any, item: any) => {
                const n = groups[item.id] || [];
                n.push(item);
                groups[item.id] = n;
                return groups;
            }, {});

            Object.entries(groupedById).forEach(([key, items]) => {
                groupedById[key] = (items as any).reduce((a: any, b: any) => {
                    b.monthLabel = months[b.month];
                    a[b.month] = b;
                    return a;
                }, {});
            });

            const groupedByIdLabels = response.reduce((groups: any, item: any) => {
                groups[item.id] = item.name;
                return groups;
            }, {});

            const datasets: any[] = [];
            Object.entries(months).forEach(([key, value]) => {
                Object.entries(groupedById).forEach(([_, value2]) => {
                    if (!Object.keys(value2 as any).includes(key)) {
                        (value2 as any)[key] = {
                            month: key,
                            monthLabel: months[key],
                            occurrences: 0
                        };
                    }
                });
            });

            const colors = GeneratorUtil.generateUniqueHexColors(Object.keys(groupedById).length);
            let idx = 0;
            Object.entries(groupedById).forEach(([key, value]) => {
                console.log(Object.values(value as any).map((v: any) => v.occurrences));
                datasets.push({
                    label: groupedByIdLabels[key],
                    data: Object.values(value as any).map((v: any) => v.occurrences),
                    backgroundColor: colors[idx],
                    borderColor: colors[idx]
                });
                idx++;
            });

            setData({
                labels: Object.values(months),
                datasets: datasets,
            });
        }
        setLoading(false);
    }

    React.useEffect(() => {
        loadData();
    }, [year]);

    return <Item>
        <Backdrop
            sx={{ color: 'secondary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

        <Stack direction="row" spacing={1} justifyContent={"space-between"} alignItems={"center"} mb={2}>
            <Typography variant="h6" gutterBottom align="left">{title}</Typography>
            <input type="number" value={year} onChange={(e) => setYear(parseInt(e.target.value))} />
        </Stack>
        
        <Line options={options} data={{
            labels: data.labels,
            datasets: data.datasets
        }} />
    </Item>
}

export default DemandsLikePerYear;
