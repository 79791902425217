import { Card, CardContent, Grid } from "@mui/material";
import { HttpStatusCode } from "axios";
import React from "react";
import StatisticService from "../../../services/StatisticService";
import SummaryBadge from "../../admin/dashboard/SummaryBadge";

const PublicStatistics = () => {

  const [totals, setTotals] = React.useState<any>({});

  const loadData = async () => {
    let [status, response] = await StatisticService.getTotalPublic();
    if (status && response && status === HttpStatusCode.Ok) {
      setTotals(response);
    }
  }

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <Card sx={{ width: '100%' }} elevation={3}>
      <CardContent sx={{backgroundColor: "#f2f2f2"}}>
        <Grid container spacing={2}>
          <Grid item md={2} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
            <SummaryBadge
              src="/static/prodotti.svg"
              value={totals?.researchProducts}
              label="PRODOTTI"
              elevation={2}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
            <SummaryBadge
              src="/static/servizi.svg"
              value={totals?.researchService}
              label="EVENTI"
              elevation={2}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
            <SummaryBadge
              src="/static/sfide.svg"
              value={totals?.challenges}
              label="SFIDE"
              elevation={2}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
            <SummaryBadge
              src="/static/bandi.svg"
              value={totals?.calls}
              label="BANDI"
              elevation={2}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
            <SummaryBadge
              src="/static/tavoli-discussione.svg"
              value={totals?.docTables}
              label="TAVOLI DI DISCUSSIONE"
              elevation={2}
            />
          </Grid>
          <Grid item md={2} sm={6} xs={6} display="flex" alignItems="center" justifyContent="center">
            <SummaryBadge
              src="/static/utenti.svg"
              value={totals?.users}
              label="UTENTI"
              elevation={2}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PublicStatistics;
