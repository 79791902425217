import { Box, Card, CardContent } from "@mui/material";
import { GridSelectionModel, itIT } from "@mui/x-data-grid";
import { HttpStatusCode } from "axios";
import React from "react";
import Swal, { SweetAlertOptions } from 'sweetalert2';
import roles from "../../../constants/roles";
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import Demand from "../../../model/database/Demand";
import Keyword from "../../../model/database/Keyword";
import ResearchConsumer from "../../../model/database/ResearchConsumer";
import DemandDTO from "../../../model/dto/DemandDTO";
import DemandsFilters from "../../../model/filters/DemandsFilters";
import ProductsFilters from "../../../model/filters/ProductsFilters";
import DemandsTableColumns from "../../../model/table/column/DemandsTableColumns";
import { DemandRow } from "../../../model/table/row/DemandRow";
import demandSortFields from "../../../model/table/sort/ProductSortFields";
import AuthContext from "../../../route/AuthContext";
import DemandService from "../../../services/DemandService";
import KeywordService from "../../../services/KeywordService";
import UserService from "../../../services/UserService";
import CustomSweetalert from "../../../theme/sweetalert";
import ModalState from "../../common/table/ModalState";
import { StyledTable } from "../../common/table/StyledTable";
import DemandInterestedModal from "./DemandInterestedModal";
import DemandModal from "./DemandModal";
import DemandsTableToolbar from "./DemandsTableToolbar";

const DemandsTable = () => {

    const authContext = React.useContext(AuthContext);

    const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
    const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0
    });
    const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
        page: 0,
        size: 10,
        sort: demandSortFields[0].value + ',asc'
    });
    const [filterRequest, setFilterRequest] = React.useState<DemandsFilters>(
        authContext.user?.role === 'RESEARCH_CONSUMER' ? {
            consumer: {
                label: 'Ente proponente',
                value: authContext.user?.id
            }
        } : {});

    const [loading, setLoading] = React.useState<boolean>(false);
    const [rows, setRows] = React.useState<DemandRow[]>([]);
    const [demands, setDemands] = React.useState<Demand[]>([]);
    const [researchConsumers, setResearchConsumers] = React.useState<ResearchConsumer[]>([]);
    const [keywords, setKeywords] = React.useState<Keyword[]>([]);

    const [modalState, setModalState] = React.useState<ModalState>({
        open: false,
        data: undefined,
        edit: false
    });
    const handleCloseModal = () => setModalState(() => ({ data: undefined, open: false, edit: false }));

    const [modalInterestedState, setModalInterestState] = React.useState<ModalState>({
        open: false,
        data: undefined,
        edit: false
    });
    const handleCloseModalInterested = () => setModalInterestState(() => ({ data: undefined, open: false, edit: false }));

    const handleShow = (id: string) => {
        setModalState({
            open: true,
            data: demands.filter(value => value.product?.id === id)[0],
            edit: false
        });
    };

    const handleShowInterested = (id: string) => {
        setModalInterestState({
            open: true,
            data: demands.filter(value => value.product?.id === id)[0],
            edit: false
        });
    };

    const handleEdit = (id: string) => {
        setModalState({
            open: true,
            data: demands.filter(value => value.product?.id === id)[0],
            edit: true
        });
    };

    const handleSave = (demandDTO: DemandDTO) => {
        return new Promise<boolean>(async resolve => {
            let [status, response] = [undefined, undefined];
            if (!modalState.data) {
                [status, response] = await DemandService.save(authContext, demandDTO);
            } else {
                [status, response] = await DemandService.update(authContext, demandDTO, modalState.data.id);
            }

            if (status && response && status === HttpStatusCode.Ok) {
                let swOptions: SweetAlertOptions = {
                    title: 'Completato',
                    text: 'Fabbisogno aggiunto correttamente.',
                    icon: 'success'
                };

                switch (response) {
                    case 'SAVED':
                        resolve(true);
                        handleCloseModal();
                        break;
                    case 'UPDATED':
                        resolve(true);
                        swOptions.text = 'Fabbisogno aggiornato correttamente.';
                        handleCloseModal();
                        break;
                    default:
                        resolve(false);
                        swOptions.title = 'Errore';
                        swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                        swOptions.icon = 'error';
                        break;
                }
                CustomSweetalert(swOptions).then(() => {
                    reloadData();
                });
            } else {
                resolve(false);
                CustomSweetalert({
                    title: 'Errore',
                    text: 'Si è verificato un problema durante il salvataggio.',
                    icon: 'error'
                });
            }

        });
    }

    const handleThreshold = (id: string) => {
        CustomSweetalert({
            title: "Imposta la threshold per il fabbisogno selezionato",
            input: "number",
            inputValue: demands.find(value => value.product?.id === id)?.threshold || 0,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (v) => {
            if (v.value !== undefined && v.value !== null && v.value > 0) {
                const [status, response] = await DemandService.updateThresholdById(authContext, id, v.value);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Threshold imposotata correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile aggiornare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile aggiornare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Elimina',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await DemandService.deleteById(authContext, id);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Fabbisogno eliminato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'DELETED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile eliminare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        })
    };

    const handleInterest = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di essere interessato al fabbisogno?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await DemandService.changeInterestById(authContext, id, true);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Interesse salvato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'SAVED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile salvare l\'operazione.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile salvare l\'operazione.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleRemoveInterest = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler rimuovere l\'interesse per il fabbisogno?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Rimuovi',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await DemandService.changeInterestById(authContext, id, false);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Interesse per il fabbisogno rimosso correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'SAVED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile salvare l\'operazione.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile salvare l\'operazione.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleCloseCall = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler chiudere questo fabbisogno? L'operazione sarà irreversibile.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await DemandService.changeStateById(authContext, id, 'CLOSED');
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Fabbisogno chiuso correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    await CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile approvare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleApprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler approvare il fabbisogno?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2e7d32',
            confirmButtonText: 'Attiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await DemandService.changeApprovedById(authContext, id, true);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Fabbisogno approvato correttamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        case 'NOT_UPDATED':
                            swOptions.title = 'Errore';
                            swOptions.text = 'L\'elemento selezionato ha parole chiave associate non approvate.';
                            swOptions.icon = 'error';
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile approvare l\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const handleDisapprove = (id: string) => {
        Swal.fire({
            title: 'Attenzione',
            text: "Confermi di voler disapprovare il fabbisogno?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            confirmButtonText: 'Disattiva',
            cancelButtonText: 'Annulla',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const [status, response] = await DemandService.changeApprovedById(authContext, id, false);
                if (status && response && status === HttpStatusCode.Ok) {
                    let swOptions: SweetAlertOptions = {
                        title: 'Completato',
                        text: 'Approvazione rimossa correettamente.',
                        icon: 'success'
                    };

                    switch (response) {
                        case 'UPDATED':
                            break;
                        default:
                            swOptions.title = 'Errore';
                            swOptions.text = 'Non è stato possibile rimuovere l\'approvazione all\'elemento selezionato.';
                            swOptions.icon = 'error';
                            break;
                    }
                    CustomSweetalert(swOptions).then(() => {
                        reloadData();
                    });
                } else
                    CustomSweetalert({
                        title: 'Attenzione',
                        text: 'Non è stato possibile rimuovere l\'approvazione all\'elemento selezionato.',
                        icon: 'error'
                    })
            }
        });
    };

    const applyFilters = (filters: ProductsFilters) => {
        setFilterRequest(filters);
    }

    const applySort = (value: string) => {
        if (pageInfo.sort !== value) {
            setPageInfo((prevState) => ({ ...prevState, sort: value }));
        }
    }

    const loadResearchConsumers = async () => {
        const [status, response] = await UserService.getAllConsumers(authContext, { page: 0, size: -1, sort: 'id,asc' });
        setResearchConsumers(response ? response._embedded.researchConsumers : []);
    }

    const loadKeywords = async () => {
        const [status, response] = await KeywordService.getAllActive(authContext, { page: 0, size: -1, sort: 'id,asc' });
        setKeywords(response ? response._embedded.keywords : []);
    }

    const mapResponseToRows = (demands: Demand[]) => {
        return demands.map(value => ({
            id: value.product?.id,
            approved: value.product?.approved,
            state: value.product?.state,
            userInterest: !!value.userLikes?.find(like => like.state === 'INTERESTED' && like.user.id === authContext.user?.id),
            data: value
        }));
    }

    const reloadData = async () => {
        setLoading(true);
        let [status, response] = [undefined, undefined];
        if (Object.keys(filterRequest).length === 0)
            [status, response] = await DemandService.getAll(authContext, pageInfo);
        else
            [status, response] = await DemandService.getAllFiltered(authContext, pageInfo, filterRequest);

        await loadResearchConsumers();
        await loadKeywords();

        if (status && response && status === HttpStatusCode.Ok) {
            setPageInfoResponse((response as any).page);
            const ds: Demand[] = (response as any)._embedded ? (response as any)._embedded.demands : [];
            setDemands(ds);
            const demandsRows: DemandRow[] = mapResponseToRows(ds);
            setRows(demandsRows);
            //setRows(authContext.user?.role !== 'RESEARCH_PRODUCER' ? productsRows : productsRows.filter(value => value.data?.producer?.user?.id === authContext.user?.id));
        } else {
            CustomSweetalert({
                title: 'Attenzione',
                text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
                icon: 'error'
            });
        }

        setLoading(false);
    };

    React.useEffect(() => {
        reloadData();
    }, [pageInfo, filterRequest]);

    return (
        <Card sx={{ minWidth: 275, mt: 2, mb: 2 }}>
            <CardContent>
                <DemandsTableToolbar
                    loggedUser={authContext.user}
                    numSelected={idSelected.length}
                    consumers={researchConsumers}
                    keywords={keywords}
                    handleNew={() => setModalState({ open: true, data: undefined, edit: false })}
                    applyFilters={applyFilters}
                    applySort={applySort}
                />
                <Box sx={{ height: 'auto', width: '100%' }}>
                    <StyledTable
                        loading={loading}
                        paginationMode="server"
                        rows={rows}
                        columns={DemandsTableColumns({
                            loggedUser: authContext.user,
                            showCallback: handleShow,
                            editCallback: handleEdit,
                            deleteCallback: handleDelete,
                            interestCallback: handleInterest,
                            closeCallback: handleCloseCall,
                            thresholdCallback: handleThreshold,
                            removeInterestCallback: handleRemoveInterest,
                            showInterestedCallback: handleShowInterested,
                            approveCallback: handleApprove,
                            disapproveCallback: handleDisapprove,
                            showApproved: authContext.user?.role !== 'RESEARCH_PRODUCER'
                        })}
                        rowsPerPageOptions={[10, 15, 20]}
                        pageSize={pageInfo.size}
                        onPageSizeChange={newPageSize => setPageInfo((prevState) => ({
                            ...prevState,
                            size: newPageSize
                        }))}
                        onPageChange={newPage => setPageInfo((prevState) => ({ ...prevState, page: newPage }))}
                        rowCount={pageInfoResponse.totalElements}
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
                        // checkboxSelection
                        getRowHeight={() => 'auto'}
                        disableSelectionOnClick
                        autoHeight
                        experimentalFeatures={{ newEditingApi: false }}
                    />
                </Box>
            </CardContent>
            <DemandModal
                open={modalState.open}
                edit={modalState.edit}
                demand={modalState.data}
                keywords={keywords}
                consumers={researchConsumers}
                loggedUser={authContext.user}
                handleClose={handleCloseModal}
                handleSave={handleSave}
            />
            {(authContext.user?.role === roles[2].id || authContext.user?.role === roles[3].id) &&
                <DemandInterestedModal
                    open={modalInterestedState.open}
                    demand={modalInterestedState.data}
                    handleClose={handleCloseModalInterested}
                />}
        </Card>
    )
}

export default DemandsTable;
