import { Button, Grid, MenuItem, TextField, Theme, useTheme } from "@mui/material";
import { HttpStatusCode } from "axios";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import devStages from "../../../constants/devStages";
import researchProductTypes from "../../../constants/researchProductTypes";
import LoggedUser from "../../../model/auth/LoggedUser";
import Keyword from "../../../model/database/Keyword";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchProduct from "../../../model/database/ResearchProduct";
import FileDTO from "../../../model/dto/FileDTO";
import ResearchProductDTO from "../../../model/dto/ResearchProductDTO";
import AuthContext from "../../../route/AuthContext";
import ProductService from "../../../services/ProductService";
import RegexUtil from "../../../utils/RegexUtil";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";
import KeywordsAutocompleteFreesolo from "../../common/select/KeywordsAutocompleteFreesolo";
import ProducersAutocomplete from "../../common/select/ProducersAutocomplete";
import UploadDocumentField from "../../common/UploadDocumentField";


interface ProductModalProps {
    open: boolean,
    edit: boolean,
    researchProduct?: ResearchProduct,
    loggedUser?: LoggedUser,
    keywords: Keyword[],
    producers: ResearchProducer[],
    handleClose: () => void,
    handleSave: (productToSave: ResearchProductDTO) => Promise<boolean>
}

function getStyles(id: string, ids: string[], theme: Theme) {
    return {
        fontWeight:
            ids.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ProductModal = ({
                          open,
                          edit,
                          loggedUser,
                          researchProduct,
                          producers,
                          keywords,
                          handleSave,
                          handleClose
                      }: ProductModalProps) => {

    const {t} = useTranslation();
    const authContext = React.useContext(AuthContext);
    const formikRef = React.useRef<any>(undefined);

    const [editMode, setEditMode] = React.useState<boolean>(edit);
    const [document, setDocument] = React.useState<FileDTO | null>(null);
    const [cleanFile, setCleanFile] = React.useState<boolean>(false);
    const [checkFileSize, setCheckFileSize] = React.useState<boolean>(true);
    const [title, setTitle] = React.useState<string>(t('product.title.new') as string);

    React.useEffect(() => {
        if (!open)
            resetAndClose();
    }, [open]);

    React.useEffect(() => {
        setEditMode(edit);
    }, [edit]);

    React.useEffect(() => {
        const loadDocument = async () => {
            if (researchProduct && researchProduct.product?.id && document === null) {
                const [status, response] = await ProductService.getDocumentResearchProductById(authContext, researchProduct.product?.id ?? '');
                if (status && status === HttpStatusCode.Ok)
                    setDocument(response);
            }
        }
        loadDocument();
    }, [researchProduct]);

    React.useEffect(() => {
        if (editMode && researchProduct){
            if(researchProduct.product?.id)
                setTitle(t('product.title.edit') as string);
            else
                setTitle(t('product.title.new') as string);
        }else if (researchProduct)
            setTitle(t('product.title.details') as string);
        else
            setTitle(t('product.title.new') as string);
    }, [editMode, researchProduct]);

    const resetAndClose = () => {
        setEditMode(false);
        setCleanFile(true);
        setDocument(null);
        if (formikRef.current)
            formikRef.current.resetForm();
        handleClose();
    }

    const theme = useTheme();

    return <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
            name: researchProduct ? researchProduct.product?.name : '',
            description: researchProduct ? researchProduct.product?.description : '',
            startDate: researchProduct ? researchProduct.product?.startDate : '',
            endDate: researchProduct ? researchProduct.product?.endDate : '',
            user: researchProduct ? researchProduct.producer?.user?.id : (loggedUser?.role === 'RESEARCH_PRODUCER' ? loggedUser.id : ''),
            keywords: researchProduct ? researchProduct.product?.keywords?.map(value => value.id) : [],
            suggested: [],
            financeType: researchProduct ? researchProduct.financeType : '',
            website: researchProduct ? researchProduct.website : '',
            trl: researchProduct ? researchProduct.trl : '',
            type: researchProduct ? researchProduct.type : '',
            patentability: researchProduct ? researchProduct.patentability : '',
            developmentStage: researchProduct ? researchProduct.developmentStage : '',
            document: researchProduct && document ? document : null
        }}
        validationSchema={Yup.object().shape({
            name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il nome è obbligatorio e non può essere vuoto.'),
            description: Yup.string().max(8192, 'Il valore massimo consentito è di 8192 caratteri')
                .required('La descrizione è obbligatorio e non può essere vuoto.'),
            user: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('Il produttore è obbligatorio e non può essere vuoto.'),
            keywords: Yup.array().of(Yup.string()).min(1, 'Almeno 1 parola chiave deve essere selezionata')
                .max(10, 'Si possono selezionare al massimo 10 parole chiave')
                .required('Le parole chiave sono obbligatorie e non possono essere vuote.'),
            type: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
                .required('La tipologia è obbligatoria e non può essere vuota.'),
            startDate: Yup.date()
                .when('endDate', {
                    is: (endDate: any) => endDate !== undefined && !isNaN(endDate.getTime()),
                    then: Yup.date().nullable().max(Yup.ref('endDate'), 'La data di inizio deve essere minore della data di fine'),
                    otherwise: Yup.date().nullable()
                })
                .nullable(),
            endDate: Yup.date()
                .nullable()
                .when('startDate', {
                    is: (startDate: any) => startDate !== undefined && !isNaN(startDate.getTime()),
                    then: Yup.date().nullable().min(Yup.ref('startDate'), 'La data di fine deve essere maggiore della data di inizio'),
                    otherwise: Yup.date().nullable()
                })
                .nullable(),
            financeType: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri'),
            website: Yup.string().max(1024, 'Il valore massimo consentito è di 1024 caratteri')
                .matches(RegexUtil.webSiteRegex,
                    'Sito web non valido'),
            trl: Yup.number().integer('Sono consentiti solo valori interi')
                .min(1, 'Il valore minimo consentito è 1').max(9, 'Il valore massimo consentito è 9'),
        }, [['startDate', 'endDate']])}
        onSubmit={async (values, {resetForm}) => {
            let result = await handleSave({
                ...values as ResearchProductDTO,
                patentability: values.patentability === 'true',
                suggested: values.suggested.filter((v: any) => values.keywords?.includes(v.id)).map((v: any) => ({name: v.name, state: v.state, description: '', sdgs: []}))
            });
            if (result) {
                resetForm();
                setEditMode(false);
            }
        }}
    >
        {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              submitForm,
              values
          }) => (
            <CustomDialog
                open={open ? open : false}
                handleClose={() => {
                    if (!isSubmitting) resetAndClose()
                }}
                title={title}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            {loggedUser && (loggedUser?.role !== 'RESEARCH_PRODUCER' || (researchProduct && loggedUser.id !== researchProduct?.producer?.id)) &&
                                <ResponsiveGridItem>
                                    <ProducersAutocomplete producers={producers} fieldName="user"
                                                           readOnly={!editMode && researchProduct !== undefined}
                                                           showMail={loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT'}
                                                           label="Produttore proponente"/>
                                </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label={t('product.name')}
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.name}
                                    required
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <KeywordsAutocompleteFreesolo
                                    keywords={researchProduct ? [...keywords, ...researchProduct.product?.keywords?.filter(k => k.state === 'INACTIVE') ?? []] : keywords}
                                    fieldName="keywords"
                                    suggestedFieldName="suggested"
                                    readOnly={!editMode && researchProduct !== undefined}
                                    label={t('product.keyword')} />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.description && errors.description)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText={(touched.description && errors.description) as React.ReactNode}
                                    label={t('product.description')}
                                    margin="normal"
                                    name="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    value={values.description}
                                    required
                                    variant="outlined"
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined
                                    }}
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={4}>
                                <TextField
                                    error={Boolean(touched.startDate && errors.startDate)}
                                    fullWidth
                                    helperText={touched.startDate && errors.startDate}
                                    label={t('product.startDate')}
                                    margin="normal"
                                    name="startDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined,
                                        max: values.endDate
                                    }}
                                    type="date"
                                    disabled={isSubmitting}
                                    value={values.startDate ?? ''}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={4}>
                                <TextField
                                    error={Boolean(touched.endDate && errors.endDate)}
                                    fullWidth
                                    helperText={touched.endDate && errors.endDate}
                                    label={t('product.endDate')}
                                    margin="normal"
                                    name="endDate"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined,
                                        min: values.startDate
                                    }}
                                    disabled={isSubmitting}
                                    value={values.endDate}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={4}>
                                <TextField
                                    error={Boolean(touched.trl && errors.trl)}
                                    fullWidth
                                    helperText={(touched.trl && errors.trl) || 'È un valore basato su una scala da 1 a 9, dove 1 è il più basso (ricerca di base) e 9 il più alto (prima produzione)'}
                                    label={t('product.trl')}
                                    margin="normal"
                                    name="trl"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.trl}
                                    variant="outlined"
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => <MenuItem key={value}
                                                                                          value={value}>Livello {value}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.financeType && errors.financeType)}
                                    fullWidth
                                    helperText={touched.financeType && errors.financeType}
                                    label={t('product.financeType')}
                                    margin="normal"
                                    name="financeType"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.financeType}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.type && errors.type)}
                                    fullWidth
                                    helperText={touched.type && errors.type}
                                    label={t('product.productType')}
                                    margin="normal"
                                    name="type"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.type}
                                    required
                                    variant="outlined"
                                >
                                    {researchProductTypes.map(value => <MenuItem key={value.value}
                                                                                 value={value.value}>{value.label}</MenuItem>)}
                                </TextField>
                            </ResponsiveGridItem>
                            {(values.type === 'PROJECT' || values.type === 'PUBLICATION') && <ResponsiveGridItem md={6}>
                                <TextField
                                    error={Boolean(touched.patentability && errors.patentability)}
                                    fullWidth
                                    helperText={touched.patentability && errors.patentability}
                                    label="Brevettabilità"
                                    margin="normal"
                                    name="patentability"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.patentability}
                                    variant="outlined"
                                >
                                    <MenuItem></MenuItem>
                                    <MenuItem value={"true"}>SI</MenuItem>
                                    <MenuItem value={"false"}>NO</MenuItem>
                                </TextField>
                            </ResponsiveGridItem>}
                            {(values.type === 'PROJECT' || values.type === 'PATENT' || values.type === 'PUBLICATION') &&
                                <ResponsiveGridItem
                                    md={values.type === 'PROJECT' || values.type === 'PUBLICATION' ? 6 : 12}>
                                    <TextField
                                        error={Boolean(touched.developmentStage && errors.developmentStage)}
                                        fullWidth
                                        helperText={touched.developmentStage && errors.developmentStage}
                                        label="Stadio di sviluppo"
                                        margin="normal"
                                        name="developmentStage"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        select
                                        inputProps={{
                                            readOnly: !editMode && researchProduct !== undefined
                                        }}
                                        disabled={isSubmitting}
                                        value={values.developmentStage}
                                        variant="outlined"
                                    >
                                        {devStages.map(value => <MenuItem key={value.value}
                                                                          value={value.value}>{value.label}</MenuItem>)}
                                    </TextField>
                                </ResponsiveGridItem>}
                            <ResponsiveGridItem>
                                <UploadDocumentField clean={cleanFile} propertyId="document"
                                                     editMode={editMode || !researchProduct} checkSize={checkFileSize} fileTypes=".pdf" />
                            </ResponsiveGridItem>
                            <ResponsiveGridItem>
                                <TextField
                                    error={Boolean(touched.website && errors.website)}
                                    fullWidth
                                    helperText={touched.website && errors.website}
                                    label={t('product.website')}
                                    margin="normal"
                                    name="website"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    inputProps={{
                                        readOnly: !editMode && researchProduct !== undefined
                                    }}
                                    disabled={isSubmitting}
                                    value={values.website}
                                    variant="outlined"
                                />
                            </ResponsiveGridItem>
                        </Grid>
                    </form>
                }
                actions={
                    <>
                        <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
                            {t('modal.button.close')}
                        </Button>
                        {loggedUser && (
                            (loggedUser.role === 'RESEARCH_PRODUCER' &&
                                (loggedUser.id === researchProduct?.producer?.id ||
                                    (loggedUser.idParent && loggedUser.idParent === researchProduct?.producer?.id)
                                )
                            ) || loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT'
                        ) && (!editMode && researchProduct !== undefined ?
                            <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
                                {t('modal.button.edit')}
                            </Button> :
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                {t('modal.button.confirm')}
                                {isSubmitting && <CircularLoading/>}
                            </Button>)}
                        {loggedUser &&
                            (loggedUser.role === 'RESEARCH_PRODUCER') && (!researchProduct || !researchProduct.product?.id) && !editMode && !researchProduct &&
                            <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
                                {t('modal.button.confirm')}
                                {isSubmitting && <CircularLoading/>}
                            </Button>}
                    </>
                }
            />
        )}
    </Formik>;
}

export default ProductModal;
