import { Box } from "@mui/material";
import React from 'react';
import { useLocation } from 'react-router-dom';
import ServicesTable from '../../components/admin/services/ServicesTable';
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';
import AdminLayout from "../../layouts/admin/AdminLayout";
import AuthContext from '../../route/AuthContext';

const Services = () => {

  const {user} = React.useContext(AuthContext);
  const location = useLocation();

  const contentBreadcrumb = BreadcrumbGenerator.generateContent(user?.role ?? '', location.pathname);

  return (
    <AdminLayout
      breadcrumb={contentBreadcrumb}
      title="Eventi | Piattaforma Innovation ReSearch"
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 1
      }}>
        <ServicesTable />
      </Box>
    </AdminLayout>
  );
};

export default Services;
