import { Assignment, CardTravel, Extension, Gavel, Group, Sms } from "@mui/icons-material";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { HttpStatusCode } from "axios";
import React from 'react';
import { useLocation } from 'react-router-dom';
import DemandsBarChart from "../../components/admin/dashboard/DemandsBarChart";
import DemandsLikePerYear from "../../components/admin/dashboard/DemandsLikePerYear";
import DemandsPieChart from "../../components/admin/dashboard/DemandsPieChart";
import PieChart from "../../components/admin/dashboard/PieChart";
import SummaryWidget from "../../components/admin/dashboard/SummaryWidget";
import VerticalBarChart from "../../components/admin/dashboard/VerticalBarChart";
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';
import callTypes from "../../constants/callTypes";
import researchConsumerType from "../../constants/researchConsumerType";
import researchProducerType from "../../constants/researchProducerType";
import researchProductTypes from "../../constants/researchProductTypes";
import researchServiceTypes from "../../constants/researchServiceTypes";
import AdminLayout from "../../layouts/admin/AdminLayout";
import AuthContext from '../../route/AuthContext';
import StatisticService from "../../services/StatisticService";

const Statistics = () => {

    const authContext = React.useContext(AuthContext);

    const location = useLocation();
    const contentBreadcrumb = BreadcrumbGenerator.generateContent(authContext.user?.role ?? '', location.pathname);

    const [loading, setLoading] = React.useState<boolean>(false);
    const consumerTypeColors = researchConsumerType.map(value => {
        switch (value.id) {
            case 'PUBLIC':
                return '#f0cb00';
            case 'PRIVATE':
                return '#bca610';
            case 'COOPERATION':
                return '#888120';
            case 'ASSOCIATION':
                return '#555c30';
        }
    });

    const producerTypeColors = researchProducerType.map(value => {
        switch (value.id) {
            case 'RESEARCH_CENTER':
                return '#213740';
            case 'LABORATORY':
                return '#2a6c57';
            case 'SPINOFF':
                return '#27982f';
            case 'RESEARCHER':
                return '#76c419';
        }
    });

    const productTypeColors = researchProductTypes.map(value => {
        switch (value.value) {
            case 'PROJECT':
                return '#b3c7f7';
            case 'PATENT':
                return '#8babf1';
            case 'PUBLICATION':
                return '#0073e6';
            case 'THESIS':
                return '#0461cf';
        }
    });

    const serviceTypeColors = researchServiceTypes.map(value => {
        switch (value.value) {
            case 'SYNC_SEMINAR':
                return '#90d8b2';
            case 'ASYNC_SEMINAR':
                return '#8dd2dd';
            case 'PILL':
                return '#8babf1';
            case 'WORKSHOP':
                return '#8b95f6';
            case 'CONFERENCE':
                return '#9b8bf4';
        }
    });

    const callsTypeColors = callTypes.map(value => {
        switch (value.value) {
            case 'REGIONAL':
                return '#00bf7d';
            case 'INTERREGIONAL':
                return '#00b4c5';
            case 'NATIONAL':
                return '#0073e6';
            case 'INTERNATIONAL':
                return '#2546f0';
        }
    });

    const [pageState, setPageState] = React.useState({
        producerRegions: [] as any[],
        producerPerRegionDataset: [
            ...researchProducerType.map((value, index) => ({
                label: value.name,
                data: [] as any[],
                backgroundColor: producerTypeColors[index]
            }))
        ],
        producerPercentageLabels: [] as any[],
        producerPercentageDataset: [
            {
                label: '###',
                data: [] as any[],
                backgroundColor: researchProducerType.map((_, index) => (producerTypeColors[index]))
            }
        ],
        consumerRegions: [] as any[],
        consumerPerRegionDataset: [
            ...researchConsumerType.map((value, index) => ({
                label: value.name,
                data: [] as any[],
                backgroundColor: consumerTypeColors[index]
            }))
        ],
        consumerPercentageLabels: [] as any[],
        consumerPercentageDataset: [
            {
                label: '###',
                data: [] as any[],
                backgroundColor: researchConsumerType.map((_, index) => (consumerTypeColors[index]))
            }
        ],
        researchProductPercentageLabels: [] as any[],
        researchProductPercentageDataset: [
            {
                label: '###',
                data: [] as any[],
                backgroundColor: researchProductTypes.map((_, index) => (productTypeColors[index]))
            }
        ],
        researchServicePercentageLabels: [] as any[],
        researchServicePercentageDataset: [
            {
                label: '###',
                data: [] as any[],
                backgroundColor: researchServiceTypes.map((_, index) => (serviceTypeColors[index]))
            }
        ],
        callsPercentageLabels: [] as any[],
        callsPercentageDataset: [
            {
                label: '###',
                data: [] as any[],
                backgroundColor: callTypes.map((_, index) => (callsTypeColors[index]))
            }
        ],
        researchProductCount: {
            approved: 0,
            disapproved: 0
        },
        researchServiceCount: {
            approved: 0,
            disapproved: 0
        },
        callCount: {
            active: 0,
            expired: 0
        },
        challengeCount: {
            approved: 0,
            disapproved: 0
        },
        discussionCount: {
            active: 0,
            closed: 0
        },
        userCount: {
            active: 0,
            inactive: 0
        }
    });

    const loadData = async () => {
        setLoading(true);
        let [status, response] = await StatisticService.getDataForCharts(authContext);
        if (status && response && status === HttpStatusCode.Ok) {
            const newPageState = { ...pageState };
            const researchProductsCount = response.researchProductsCount;
            const researchServicesCount = response.researchServicesCount;
            const callsCount = response.callsCount;
            const challengesCount = response.challengesCount;
            const discussionTablesCount = response.discussionTablesCount;
            const usersCount = response.usersCount;

            newPageState.researchProductCount.approved = researchProductsCount.find((value: any) => value.state)?.count ?? 0;
            newPageState.researchProductCount.disapproved = researchProductsCount.find((value: any) => !value.state)?.count ?? 0;

            newPageState.researchServiceCount.approved = researchServicesCount.find((value: any) => value.state)?.count ?? 0;
            newPageState.researchServiceCount.disapproved = researchServicesCount.find((value: any) => !value.state)?.count ?? 0;

            newPageState.challengeCount.approved = challengesCount.find((value: any) => value.state)?.count ?? 0;
            newPageState.challengeCount.disapproved = challengesCount.find((value: any) => !value.state)?.count ?? 0;

            newPageState.callCount.active = callsCount.find((value: any) => value.state.toUpperCase() === 'ACTIVE')?.count ?? 0;
            newPageState.callCount.expired = callsCount.find((value: any) => value.state.toUpperCase() === 'EXPIRED')?.count ?? 0;

            newPageState.discussionCount.active = discussionTablesCount.find((value: any) => value.state.toUpperCase() === 'ACTIVE')?.count ?? 0;
            newPageState.discussionCount.closed = discussionTablesCount.find((value: any) => value.state.toUpperCase() === 'CLOSED')?.count ?? 0;

            newPageState.userCount.active = usersCount.find((value: any) => value.state.toUpperCase() === 'ACTIVE')?.count ?? 0;
            newPageState.userCount.inactive = usersCount.find((value: any) => value.state.toUpperCase() === 'INACTIVE')?.count ?? 0;

            // produttore
            const regionsProducer = response.producersCountByTypeAndRegion.map((value: any) => value.region);
            newPageState.producerRegions = Array.from(new Set(regionsProducer));

            newPageState.producerPercentageLabels = researchProducerType.map(value => value.label);
            newPageState.producerPercentageDataset[0].data = new Array(researchProducerType.length).fill(0);

            const totalProducer = response.producersCountByType.reduce((partialSum: number, value: any) => partialSum + value.count, 0);

            researchProducerType.forEach((value, index) => {
                const typeAndRegionFiltered = response.producersCountByTypeAndRegion.filter((value1: any) => value1.type === value.id);
                let data = new Array(newPageState.producerRegions.length).fill(0);
                typeAndRegionFiltered.forEach((value1: any) => {
                    const idx = newPageState.producerRegions.indexOf(value1.region);
                    if (idx !== -1) {
                        data[idx] = value1.count;
                    }
                });
                newPageState.producerPerRegionDataset[index].data = data;

                const typeFiltered = response.producersCountByType.find((value1: any) => value1.state === value.id);
                if (typeFiltered) {
                    newPageState.producerPercentageDataset[0].data[index] = (typeFiltered.count / totalProducer) * 100;
                }
            });

            const regions = response.consumersCountByTypeAndRegion.map((value: any) => value.region);
            newPageState.consumerRegions = Array.from(new Set(regions));

            newPageState.consumerPercentageLabels = researchConsumerType.map(value => value.name);
            newPageState.consumerPercentageDataset[0].data = new Array(researchConsumerType.length).fill(0);

            const totalConsumer = response.consumersCountByType.reduce((partialSum: number, value: any) => partialSum + value.count, 0);
            researchConsumerType.forEach((value, index) => {
                const typeAndRegionFiltered = response.consumersCountByTypeAndRegion.filter((value1: any) => value1.type === value.id);
                let data = new Array(newPageState.consumerRegions.length).fill(0);
                typeAndRegionFiltered.forEach((value1: any) => {
                    const idx = newPageState.consumerRegions.indexOf(value1.region);
                    if (idx !== -1) {
                        data[idx] = value1.count;
                    }
                });
                newPageState.consumerPerRegionDataset[index].data = data;

                const typeFiltered = response.consumersCountByType.find((value1: any) => value1.state === value.id);
                if (typeFiltered) {
                    newPageState.consumerPercentageDataset[0].data[index] = (typeFiltered.count / totalConsumer) * 100;
                }
            });

            newPageState.researchProductPercentageLabels = researchProductTypes.map(value => value.label);
            newPageState.researchProductPercentageDataset[0].data = new Array(researchProductTypes.length).fill(0);

            const totalProducts = response.researchProductCountByType.reduce((partialSum: number, value: any) => partialSum + value.count, 0);

            researchProductTypes.forEach((value, index) => {
                const typeFiltered = response.researchProductCountByType.find((value1: any) => value1.state === value.value);
                if (typeFiltered) {
                    newPageState.researchProductPercentageDataset[0].data[index] = (typeFiltered.count / totalProducts) * 100;
                }
            });

            newPageState.researchServicePercentageLabels = researchServiceTypes.map(value => value.label);
            newPageState.researchServicePercentageDataset[0].data = new Array(researchServiceTypes.length).fill(0);

            const totalServices = response.researchServiceCountByType.reduce((partialSum: number, value: any) => partialSum + value.count, 0);

            researchServiceTypes.forEach((value, index) => {
                const typeFiltered = response.researchServiceCountByType.find((value1: any) => value1.state === value.value);
                if (typeFiltered) {
                    newPageState.researchServicePercentageDataset[0].data[index] = (typeFiltered.count / totalServices) * 100;
                }
            });

            newPageState.callsPercentageLabels = callTypes.map(value => value.label);
            newPageState.callsPercentageDataset[0].data = new Array(callTypes.length).fill(0);

            const totalCalls = response.callCountByType.reduce((partialSum: number, value: any) => partialSum + value.count, 0);

            callTypes.forEach((value, index) => {
                const typeFiltered = response.callCountByType.find((value1: any) => value1.state === value.value);
                if (typeFiltered) {
                    newPageState.callsPercentageDataset[0].data[index] = (typeFiltered.count / totalCalls) * 100;
                }
            });

            setPageState(newPageState);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        loadData();
    }, []);

    return (
        <AdminLayout
            breadcrumb={contentBreadcrumb}
            title="Statistiche | Piattaforma Innovation ReSearch"
        >
            <Backdrop
                sx={{ color: 'secondary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} mt={1}>
                <Grid item md={4} sm={6} xs={12}>
                    <SummaryWidget
                        title="Prodotti"
                        icon={<CardTravel />}
                        values={[pageState.researchProductCount.approved, pageState.researchProductCount.disapproved]}
                        labels={["Accettati", "Pendenti"]}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <SummaryWidget
                        title="Eventi"
                        icon={<Sms />}
                        values={[pageState.researchServiceCount.approved, pageState.researchServiceCount.disapproved]}
                        labels={["Accettati", "Pendenti"]}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <SummaryWidget
                        title="Sfide"
                        icon={<Extension />}
                        values={[pageState.challengeCount.approved, pageState.challengeCount.disapproved]}
                        labels={["Accettate", "Pendenti"]}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <SummaryWidget
                        title="Bandi"
                        icon={<Assignment />}
                        values={[pageState.callCount.active, pageState.callCount.expired]}
                        labels={["Aperti", "Scaduti"]}
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <SummaryWidget
                        icon={<Gavel />}
                        values={[pageState.discussionCount.active, pageState.discussionCount.closed]}
                        labels={["Aperti", "Chiusi"]}
                        title="Tavoli di discussione"
                    />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <SummaryWidget
                        icon={<Group />}
                        values={[pageState.userCount.active, pageState.userCount.inactive]}
                        labels={["Accettati", "Pendenti"]}
                        title="Utenti"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} pb={1} alignItems="center">
                <Grid item md={4} sm={12} xs={12}>
                    <PieChart
                        title="Distribuzione percentuale dei prodotti"
                        labels={pageState.researchProductPercentageLabels}
                        datasets={pageState.researchProductPercentageDataset} />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <PieChart
                        title="Distribuzione percentuale dei servizi"
                        labels={pageState.researchServicePercentageLabels}
                        datasets={pageState.researchServicePercentageDataset} />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <PieChart
                        title="Distribuzione percentuale dei bandi"
                        labels={pageState.callsPercentageLabels}
                        datasets={pageState.callsPercentageDataset} />
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                    <VerticalBarChart
                        title="Distribuzione per regione dei consumatori di ricerca per tipologia"
                        labels={pageState.consumerRegions}
                        datasets={pageState.consumerPerRegionDataset}
                    />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <PieChart
                        title="Distribuzione percentuale dei consumatori di ricerca"
                        labels={pageState.consumerPercentageLabels}
                        datasets={pageState.consumerPercentageDataset} />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} pb={1} alignItems="center">
                <Grid item md={4} sm={12} xs={12}>
                    <PieChart
                        title="Distribuzione percentuale dei produttori di ricerca"
                        labels={pageState.producerPercentageLabels}
                        datasets={pageState.producerPercentageDataset} />
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                    <VerticalBarChart
                        title="Distribuzione per regione dei produttori di ricerca per tipologia"
                        labels={pageState.producerRegions}
                        datasets={pageState.producerPerRegionDataset}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={4} alignItems="center">
                <Grid item md={6} xs={12}>
                    <DemandsBarChart
                        title="Top 10 fabbisogni da evadere"
                        state='ACTIVE'
                        topK={10}
                        secondary={false}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <DemandsBarChart
                        title="Top 10 fabbisogni evasi"
                        state='CLOSED'
                        topK={10}
                        secondary={true}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <DemandsPieChart
                        title="Distribuzione parole chiave per fabbisogno"
                    />
                </Grid>
                <Grid item md={8} xs={12}>
                    <DemandsLikePerYear
                        title="Numero di like per fabbisogno nell'anno"
                    />
                </Grid>
            </Grid>

        </AdminLayout>
    );
};

export default Statistics;
