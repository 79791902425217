import { Box, Card, CardContent } from "@mui/material";
import { GridSelectionModel, itIT } from "@mui/x-data-grid";
import { HttpStatusCode } from "axios";
import React from "react";
import Swal, { SweetAlertOptions } from 'sweetalert2';
import PageInfoRequest from "../../../model/common/PageInfoRequest";
import PageInfoResponse from "../../../model/common/PageInfoResponse";
import Keyword from "../../../model/database/Keyword";
import ResearchProducer from "../../../model/database/ResearchProducer";
import ResearchService from "../../../model/database/ResearchService";
import ResearchServiceDTO from "../../../model/dto/ResearchServiceDTO";
import ServicesFilters from "../../../model/filters/ServicesFilters";
import ServicesTableColumns from "../../../model/table/column/ServicesTableColumns";
import { ServiceRow } from "../../../model/table/row/ServiceRow";
import serviceSortFields from "../../../model/table/sort/ServiceSortFields";
import AuthContext from "../../../route/AuthContext";
import KeywordService from "../../../services/KeywordService";
import ProductService from "../../../services/ProductService";
import UserService from "../../../services/UserService";
import CustomSweetalert from "../../../theme/sweetalert";
import ModalState from "../../common/table/ModalState";
import { StyledTable } from "../../common/table/StyledTable";
import ServiceModal from "./ServiceModal";
import ServicesTableToolbar from "./ServicesTableToolbar";


const ServicesTable = () => {

  const authContext = React.useContext(AuthContext);

  const [idSelected, setIdSelected] = React.useState<GridSelectionModel>([]);
  const [pageInfoResponse, setPageInfoResponse] = React.useState<PageInfoResponse>({
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0
  });
  const [pageInfo, setPageInfo] = React.useState<PageInfoRequest>({
    page: 0,
    size: 10,
    sort: serviceSortFields[0].value + ',asc'
  });
  const [filterRequest, setFilterRequest] = React.useState<ServicesFilters>(authContext.user?.role === 'RESEARCH_PRODUCER' ? {
    user: {
      label: 'Produttore',
      value: authContext.user?.idParent ? authContext.user?.idParent : authContext.user?.id
    }
  } : {});

  const [loading, setLoading] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<ServiceRow[]>([]);
  const [researchServices, setResearchServices] = React.useState<ResearchService[]>([]);
  const [researchProducers, setResearchProducers] = React.useState<ResearchProducer[]>([]);
  const [keywords, setKeywords] = React.useState<Keyword[]>([]);


  const [modalState, setModalState] = React.useState<ModalState>({
    open: false,
    data: undefined,
    edit: false
  });
  const handleCloseModal = () => setModalState(() => ({ data: undefined, open: false, edit: false }));

  const handleShow = (id: string) => {
    setModalState({
      open: true,
      data: researchServices.filter(value => value.product?.id === id)[0],
      edit: false
    });
  };

  const handleEdit = (id: string) => {
    setModalState({
        open: true,
        data: researchServices.filter(value => value.product?.id === id)[0],
        edit: true
    });
};

const handleClone = (id: string) => {
  const service = researchServices.find(value => value.product?.id === id);
  const producer = ['ADMINISTRATOR', 'COMMITEE_MANAGEMENT'].includes(authContext.user?.role ?? '') ? undefined : researchProducers.find(value => value.id === authContext.user?.id);
  setModalState({
      open: true,
      data: {...service, producer: producer, product: {...service?.product, id: undefined, keywords: []}},
      edit: true
  });
};

  const handleSave = (serviceToSave: ResearchServiceDTO) => {
    return new Promise<boolean>(async resolve => {
      let [status, response] = [undefined, undefined];
      if (!modalState.data || !modalState.data?.product?.id) {
        [status, response] = await ProductService.saveResearchService(authContext, serviceToSave);
      } else {
        [status, response] = await ProductService.updateResearchService(authContext, serviceToSave, modalState.data.id);
      }

      if (status && response && status === HttpStatusCode.Ok) {
        let swOptions: SweetAlertOptions = {
          title: 'Completato',
          text: 'Evento aggiunto correttamente.',
          icon: 'success'
        };

        switch (response) {
          case 'SAVED':
            resolve(true);
            handleCloseModal();
            break;
          case 'UPDATED':
            resolve(true);
            swOptions.text = 'Evento aggiornato correttamente.';
            handleCloseModal();
            break;
          default:
            resolve(false);
            swOptions.title = 'Errore';
            swOptions.text = 'Si è verificato un problema durante il salvataggio.';
            swOptions.icon = 'error';
            break;
        }
        CustomSweetalert(swOptions).then(() => {
          reloadData();
        });
      }
    });
  }

  const handleDelete = (id: string) => {
    Swal.fire({
      title: 'Sei sicuro?',
      text: "L'operazione è irreversibile. I dati non potranno essere recuperati.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await ProductService.deleteById(authContext, id);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Evento eliminato correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'DELETED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile eliminare l\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile eliminare l\'elemento selezionato.',
            icon: 'error'
          })
      }
    })
  };

  const handleApprove = (id: string) => {
    Swal.fire({
      title: 'Attenzione',
      text: "Confermi di voler approvare il evento?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2e7d32',
      confirmButtonText: 'Attiva',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await ProductService.changeApprovedById(authContext, id, true);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Evento approvato correttamente.',
            icon: 'success'
          };

          switch (response) {
            case 'UPDATED':
              break;
            case 'NOT_UPDATED':
              swOptions.title = 'Errore';
              swOptions.text = 'L\'elemento selezionato ha parole chiave associate non approvate.';
              swOptions.icon = 'error';
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile approvare l\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile approvare l\'elemento selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const handleDisapprove = (id: string) => {
    Swal.fire({
      title: 'Attenzione',
      text: "Confermi di voler disapprovare il evento?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d32f2f',
      confirmButtonText: 'Disattiva',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const [status, response] = await ProductService.changeApprovedById(authContext, id, false);
        if (status && response && status === HttpStatusCode.Ok) {
          let swOptions: SweetAlertOptions = {
            title: 'Completato',
            text: 'Approvazione rimossa correettamente.',
            icon: 'success'
          };

          switch (response) {
            case 'UPDATED':
              break;
            default:
              swOptions.title = 'Errore';
              swOptions.text = 'Non è stato possibile rimuovere l\'approvazione all\'elemento selezionato.';
              swOptions.icon = 'error';
              break;
          }
          CustomSweetalert(swOptions).then(() => {
            reloadData();
          });
        } else
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile rimuovere l\'approvazione all\'elemento selezionato.',
            icon: 'error'
          })
      }
    });
  };

  const applyFilters = (filters: ServicesFilters) => {
    setFilterRequest(filters);
  }

  const applySort = (value: string) => {
    if (pageInfo.sort !== value) {
      setPageInfo((prevState) => ({ ...prevState, sort: value }));
    }
  }

  const loadResearchProducers = async () => {
    const [_, response] = await UserService.getAllProducers(authContext, { page: 0, size: -1, sort: 'id,asc' });
    setResearchProducers(response ? response._embedded.researchProducers : []);
  }

  const loadKeywords = async () => {
    const [_, response] = await KeywordService.getAllActive(authContext, { page: 0, size: -1, sort: 'id,asc' });
    setKeywords(response ? response._embedded.keywords : []);
  }

  const mapResponseToRows = (services: ResearchService[]) => {
    return services.map(value => ({
      id: value.product?.id,
      approved: value.product?.approved,
      data: value
    }));
  }

  const reloadData = async () => {
    setLoading(true);
    let [status, response] = [undefined, undefined];
    if (Object.keys(filterRequest).length === 0)
      [status, response] = await ProductService.getAllResearchServices(authContext, pageInfo);
    else
      [status, response] = await ProductService.getAllResearchServiceFiltered(authContext, pageInfo, filterRequest);

    await loadResearchProducers();
    await loadKeywords();

    if (status && response && status === HttpStatusCode.Ok) {
      setPageInfoResponse((response as any).page);
      const services: ResearchService[] = (response as any)._embedded ? (response as any)._embedded.researchServices.map((value: any) => ({
        ...value,
        product: {
          ...value.product,
          user: value.producer
        }
      })) : [];
      setResearchServices(services);
      const servicesRows: ServiceRow[] = mapResponseToRows(services);
      setRows(servicesRows);
      //setRows(authContext.user?.role !== 'RESEARCH_PRODUCER' ? servicesRows : servicesRows.filter(value => value.data?.producer?.user?.id === authContext.user?.id));
    } else {
      CustomSweetalert({
        title: 'Attenzione',
        text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
        icon: 'error'
      });
    }

    setLoading(false);
  };

  React.useEffect(() => {
    reloadData();
  }, [pageInfo, filterRequest]);

  return (
    <Card sx={{ minWidth: 275, mt: 2, mb: 2 }}>
      <CardContent>
        <ServicesTableToolbar
          loggedUser={authContext.user}
          numSelected={idSelected.length}
          producers={researchProducers}
          keywords={keywords}
          handleNew={() => setModalState({ open: true, data: undefined, edit: false })}
          applyFilters={applyFilters}
          applySort={applySort}
        />
        <Box sx={{ height: 'auto', width: '100%' }}>
          <StyledTable
            loading={loading}
            paginationMode="server"
            rows={rows}
            columns={ServicesTableColumns({
              loggedUser: authContext.user,
              showCallback: handleShow,
              editCallback: handleEdit,
              cloneCallback: handleClone,
              deleteCallback: handleDelete,
              approveCallback: handleApprove,
              disapproveCallback: handleDisapprove,
              showApproved: authContext.user?.role !== 'RESEARCH_CONSUMER'
            })}
            rowsPerPageOptions={[10, 15, 20]}
            pageSize={pageInfo.size}
            onPageSizeChange={newPageSize => setPageInfo((prevState) => ({ ...prevState, size: newPageSize }))}
            onPageChange={newPage => setPageInfo((prevState) => ({ ...prevState, page: newPage }))}
            rowCount={pageInfoResponse.totalElements}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            // onSelectionModelChange={selectionModel => setIdSelected(selectionModel)}
            // checkboxSelection
            getRowHeight={() => 'auto'}
            disableSelectionOnClick
            autoHeight
            experimentalFeatures={{ newEditingApi: false }}
          />
        </Box>
      </CardContent>
      <ServiceModal
        open={modalState.open}
        edit={modalState.edit}
        researchService={modalState.data}
        keywords={keywords}
        producers={researchProducers}
        loggedUser={authContext.user}
        handleClose={handleCloseModal}
        handleSave={handleSave}
      />
    </Card>
  )
}

export default ServicesTable;
