import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import ProductCard from "../../../components/common/table/ProductCard";
import ServiceCard from "../../../components/common/table/ServiceCard";
import ResearchProduct from "../../database/ResearchProduct";
import ResearchService from "../../database/ResearchService";
import { CommonColumnProps } from "../CommonColumnProps";

interface ProductsPublicTableColumnsProps {
}

const ProductsPublicTableColumns = ({}: ProductsPublicTableColumnsProps): GridColDef[] => [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
        ...CommonColumnProps
    },
    {
        field: 'data',
        headerName: 'Prodotti ed eventi',
        renderCell: (params) => {

            let dataRP: ResearchProduct;
            let dataRS: ResearchService;

            if (params.row.type === 'PRODUCT')
                dataRP = {
                    ...params.row.data.researchProduct,
                    product: {
                        ...params.row.data,
                        user: {
                            ...params.row.data.user,
                            name: params.row.data.user.researchProducer.name
                        }
                    }
                }

            if (params.row.type === 'SERVICE')
                dataRS = {
                    ...params.row.data.researchService,
                    product: {
                        ...params.row.data,
                        user: {
                            ...params.row.data.user,
                            name: params.row.data.user.researchProducer.name
                        }
                    }
                }

            return <Box my={2} mx={1} width="100%">
                {params.row.type === 'PRODUCT' &&
                    <ProductCard key={params.row.id} researchProduct={dataRP!} showState={false}/>}
                {params.row.type === 'SERVICE' &&
                    <ServiceCard key={params.row.id} researchService={dataRS!} showState={false}/>}
            </Box>
        },
        ...CommonColumnProps,
        minWidth: 100,
        align: 'center',
        flex: 10,
    }
];

export default ProductsPublicTableColumns;
