import { Navigate } from "react-router-dom";
import Account from "../pages/administration/Account";
import Calls from "../pages/administration/Calls";
import Categories from "../pages/administration/Categories";
import Challenges from "../pages/administration/Challenges";
import Demands from "../pages/administration/Demands";
import DemandStatistics from "../pages/administration/DemandStatistics";
import Discussions from "../pages/administration/Discussions";
import Keywords from "../pages/administration/Keywords";
import Payments from "../pages/administration/Payments";
import Products from "../pages/administration/Products";
import Services from "../pages/administration/Services";
import Settings from "../pages/administration/Settings";
import Statistics from "../pages/administration/Statistics";
import Users from "../pages/administration/Users";
import ForgotPassword from "../pages/public/ForgotPassword";
import HomePage from "../pages/public/HomePage";
import Login from "../pages/public/Login";
import PrivacyPolicy from "../pages/public/PrivacyPolicy";
import PublicProducts from "../pages/public/Products";
import Registration from "../pages/public/Registration";
import RestorePassword from "../pages/public/RestorePassword";

const routes = [
  { authenticaed: true, path: '/app/statistics', element: <Statistics />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'] },
  { authenticaed: true, path: '/app/demand-statistics', element: <DemandStatistics />, roles: ['EXTERNAL_AGENCY'] },
  { authenticaed: true, path: '/app/products', element: <Products />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'] },
  { authenticaed: true, path: '/app/demands', element: <Demands />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'] },
  { authenticaed: true, path: '/app/services', element: <Services />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'] },
  { authenticaed: true, path: '/app/discussions', element: <Discussions />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'] },
  { authenticaed: true, path: '/app/calls', element: <Calls />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'] },
  { authenticaed: true, path: '/app/challenges', element: <Challenges />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'] },
  { authenticaed: true, path: '/app/users', element: <Users />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'] },
  { authenticaed: true, path: '/app/settings', element: <Settings />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'] },
  { authenticaed: true, path: '/app/keywords', element: <Keywords />, roles: ['ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'] },
  { authenticaed: true, path: '/app/categories', element: <Categories />, roles: ['ADMINISTRATOR'] },
  { authenticaed: true, path: '/app/payments', element: <Payments />, roles: ['ADMINISTRATOR'] },
  { authenticaed: true, path: '/app/profile', element: <Account />, roles: ['ADMINISTRATOR', 'EXTERNAL_AGENCY', 'COMMITTEE_MANAGEMENT', 'RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'] },
  { authenticaed: false, path: '/login', element: <Login /> },
  // { path: '/404', element: <MainLayout><NotFound /></MainLayout> },
  { authenticaed: false, path: '/', element: <HomePage /> },
  { authenticaed: false, path: '/products', element: <PublicProducts /> },
  { authenticaed: false, path: '/logout', element: <Navigate to="/login" /> },
  { authenticaed: false, path: '/registration', element: <Registration /> },
  { authenticaed: false, path: '/forgot-password', element: <ForgotPassword /> },
  { authenticaed: false, path: '/restore-password', element: <RestorePassword /> },
  { authenticaed: false, path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '*', element: <Navigate to="/" /> }
];

export default routes;
