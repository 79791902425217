const generateContent = (role: string, page: string): string[] => {
  const content = [];
  switch (role) {
    case 'RESEARCH_PRODUCER':
      content.push('Produttori di ricerca');
      break;
    case 'EXTERNAL_AGENCY':
        content.push('Agenzia esterna');
        break;
    case 'RESEARCH_CONSUMER':
      content.push('Consumatori della ricerca');
      break;
    case 'ADMINISTRATOR':
      content.push('Amministrazione');
      break;
    case 'COMMITTEE_MANAGEMENT':
      content.push('Comitato di gestione e indirizzo');
      break;
  }

  switch (page) {
    case '/app/statistics':
      content.push('Statistiche');
      break;
    case '/app/demand-statistics':
      content.push('Statistiche fabbisogni');
      break;
    case '/app/products':
      content.push('Gestione prodotti');
      break;
    case '/app/services':
      content.push('Gestione eventi');
      break;
    case '/app/users':
      content.push('Gestione utenti');
      break;
    case '/app/discussions':
      content.push('Gestione tavoli di discussione');
      break;
    case '/app/calls':
      content.push('Gestione bandi');
      break;
    case '/app/challenges':
      content.push('Gestione sfide');
      break;
    case '/app/payments':
      content.push('Gestione pagamenti');
      break;
    case '/app/keywords':
      content.push('Gestione parole chiave');
      break;
    case '/app/settings':
      content.push('Impostazioni di sistema');
      break;
    case '/app/profile':
      content.push('Profilo utente');
      break;
  }

  return content;
}

const BreadcrumbGenerator = {
  generateContent
}

export default BreadcrumbGenerator;