import { LabelValue } from "../model/filters/LabelValue";

const researchProductTypes: LabelValue[] = [
    {
        label: 'Progetto',
        value: 'PROJECT',
    },
    {
        label: 'Brevetto',
        value: 'PATENT',
    },
    {
        label: 'Pubblicazione',
        value: 'PUBLICATION',
    },
    {
        label: 'Servizio specialistico',
        value: 'SERVICE_SPECIFIC',
    },
    // {
    //     label: 'Tirocinio',
    //     value: 'INTERNSHIP',
    // },
    {
        label: 'Tesi',
        value: 'THESIS',
    },
];

export default researchProductTypes;