import { AutoStories, ContactMail, ExpandLess, ExpandMore, Info, Logout, ManageAccounts } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Hidden,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip
} from '@mui/material';
import { HttpStatusCode } from "axios";
import React from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import InfoModal from "../../components/common/InfoModal";
import InviteModal from "../../components/common/InviteModal";
import LoggedUser from "../../model/auth/LoggedUser";
import AuthContext from '../../route/AuthContext';
import AuthService from '../../services/AuthService';
import UtilityService from "../../services/UtilityService";
import CustomSweetalert from "../../theme/sweetalert";

interface DashboardNavbarProps {
  onMobileNavOpen: () => any,

  [x: string]: any
}

const userOptions = [
  {
    id: 'profile',
    text: 'Profilo',
    icon: <ManageAccounts sx={{ mr: 2, color: 'secondary.contrastText' }} />,
    divider: (ctx?: LoggedUser) => ['ADMINISTRATOR', 'EXTERNAL_AGENCY', 'COMMITTEE_MANAGEMENT'].includes(ctx?.role ?? ''),
    roles: ['RESEARCH_PRODUCER', 'EXTERNAL_AGENCY', 'RESEARCH_CONSUMER', 'ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    href: '/app/profile'
  },
  {
    id: 'invite',
    text: 'Invia invito',
    icon: <ContactMail sx={{ mr: 2, color: 'secondary.contrastText' }} />,
    divider: (ctx?: LoggedUser) => true,
    roles: ['RESEARCH_PRODUCER', 'RESEARCH_CONSUMER'],
    href: '#invite'
  },
  {
    id: 'dwnlDoc',
    text: 'Scarica manuale',
    icon: <AutoStories sx={{ mr: 2, color: 'secondary.contrastText' }} />,
    divider: (ctx?: LoggedUser) => ['RESEARCH_CONSUMER', 'ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'].includes(ctx?.role ?? ''),
    roles: ['RESEARCH_PRODUCER', 'RESEARCH_CONSUMER', 'ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    href: '#dwnlDoc'
  },
  {
    id: 'dwnlDocMinified',
    text: 'Scarica manuale semplificato',
    icon: <AutoStories sx={{ mr: 2, color: 'secondary.contrastText' }} />,
    roles: ['RESEARCH_PRODUCER'],
    divider: (ctx?: LoggedUser) => true,
    href: '#dwnlDocMinified'
  },
  {
    id: 'info',
    text: 'Informazioni',
    icon: <Info sx={{ mr: 2, color: 'secondary.contrastText' }} />,
    divider: (ctx?: LoggedUser) => true,
    roles: ['RESEARCH_PRODUCER', 'RESEARCH_CONSUMER', 'EXTERNAL_AGENCY', 'ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    href: '#info'
  },
  {
    id: 'logout',
    text: 'Disconnetti',
    icon: <Logout sx={{ mr: 2, color: 'secondary.contrastText' }} />,
    divider: (ctx?: LoggedUser) => false,
    roles: ['RESEARCH_PRODUCER', 'RESEARCH_CONSUMER', 'EXTERNAL_AGENCY', 'ADMINISTRATOR', 'COMMITTEE_MANAGEMENT'],
    href: '#logout'
  }
];

const AdminNavbar = ({ onMobileNavOpen, ...rest }: DashboardNavbarProps) => {
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [inviteOpen, setInviteOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const authContext = React.useContext(AuthContext);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const { user, setUser } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    if (setUser) {
      AuthService.logout(setUser);
      navigate('/logout');
    }
  }

  const handleInvite = () => {
    setInviteOpen(true);
  }

  const handleDownload = (type?: string) => {
    Swal.fire({
      title: "Download file in corso...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: async () => {
        Swal.showLoading();
        // recall the utilityservice to download the file
        const [status, response] = await UtilityService.download(authContext, type);
        if (status && response && status === HttpStatusCode.Ok) {
          const headers = response.headers;
          const filename = headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
          const blob = new Blob([response.data], { type: headers['content-type'] });

          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();

          Swal.close();
        } else {
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Non è stato possibile scarica il documento richiesto.',
            icon: 'error'
          }).then(() => {
            Swal.close();
          });
        }
      }
    });
  }

  return (
    <AppBar
      elevation={0}
      color="transparent"
      {...rest}
    >
      <Toolbar sx={{ mt: 0.5 }}>
        <Hidden lgUp>
          <Tooltip title="Menu">
            <IconButton
              color="inherit"
              onClick={onMobileNavOpen}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{
          flexGrow: 0,
          backgroundColor: 'secondary.main',
          borderRadius: 2
        }}>
          <Tooltip title="Mostra impostazioni">
            <ListItemButton onClick={handleOpenUserMenu}>
              <ListItemIcon>
                <Avatar sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>{user?.name?.charAt(0).toUpperCase()}{user?.surname?.charAt(0).toUpperCase()}</Avatar>
              </ListItemIcon>
              <ListItemText sx={{ mr: 1, color: 'secondary.contrastText' }}>{user?.name} {user?.surname}</ListItemText>
              {Boolean(anchorElUser) ? <ExpandLess sx={{ color: "secondary.contrastText" }} /> : <ExpandMore sx={{ color: "secondary.contrastText" }} />}
            </ListItemButton>
          </Tooltip>
          <Menu
            sx={{
              mt: '48px',
              '& .MuiPaper-root': {
                backgroundColor: 'secondary.main'
              }
            }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {userOptions.map((option) => (
              option.roles?.includes(authContext.user?.role ?? '') && 
                <RouterLink key={option.id} to={option.href} style={{ textDecoration: "none", color: "inherit" }}>
                  <MenuItem sx={{ pl: 2, color: 'secondary.contrastText' }} onClick={() => {
                    if (option.href === '#info') setInfoOpen(true);
                    if (option.href === '#invite') handleInvite();
                    if (option.href === '#logout') handleLogout();
                    if (option.href === '#dwnlDoc') handleDownload();
                    if (option.href === '#dwnlDocMinified') handleDownload('minified');
                  }}>
                    {option.icon}
                    {option.text}
                  </MenuItem>
                  {option.divider(authContext.user) && <Divider color="#FFFFFF" variant="middle" />}
                </RouterLink>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      <InfoModal handleClose={() => setInfoOpen(false)} open={infoOpen} />
      <InviteModal handleClose={() => setInviteOpen(false)} open={inviteOpen} />
    </AppBar>
  );
};

export default AdminNavbar;
