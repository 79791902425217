import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { HttpStatusCode } from "axios";
import { TooltipItem } from "chart.js";
import React from "react";
import { Pie } from 'react-chartjs-2';
import AuthContext from "../../../route/AuthContext";
import StatisticService from '../../../services/StatisticService';
import GeneratorUtil from "../../../utils/GeneratorUtil";
import Item from "../../common/Item";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: false
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label(tooltipItem: TooltipItem<'pie'>): string | string[] {
                    return `  ${tooltipItem.label}: ${tooltipItem.parsed.toFixed(2)} %`;
                }
            }
        }
    },
};

interface DemandsPieChartProps {
    title: string
}

const DemandsPieChart = ({ title }: DemandsPieChartProps) => {
    const authContext = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState({
        labels: [] as any[],
        datasets: [] as any[]
    });

    const loadData = async () => {
        setLoading(true);
        let [status, response] = await StatisticService.getDemandsCountByKeyword(authContext);
        if (status && response && status === HttpStatusCode.Ok) {
            const total = response.reduce((a: number, b: any) => a + b.count, 0);
            const newData = {
                labels: response.map((value: any) => value.name),
                datasets: [{
                    data: response.map((value: any) => (value.count / total) * 100),
                    backgroundColor: GeneratorUtil.generateUniqueHexColors(response.length)
                }]
            }
            setData(newData);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        loadData();
    }, []);

    return <Item>
        <Backdrop
            sx={{ color: 'secondary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        <Typography variant="h6" gutterBottom align="left" mb={2}>{title}</Typography>
        <Pie redraw options={options} data={{
            labels: data.labels,
            datasets: data.datasets
        }} />
    </Item>
}

export default DemandsPieChart;
