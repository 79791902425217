import { Chip, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Roles from "../../../constants/roles";
import LoggedUser from "../../../model/auth/LoggedUser";
import Demand from "../../../model/database/Demand";
import ResponsiveGridItem from "../ResponsiveGridItem";
import StringGeneratorUtil from "../StringGeneratorUtil";
import KeywordsBadges from "./KeywordsBadges";

interface DemandCardProps {
  useCard?: boolean,
  user?: LoggedUser,
  demand?: Demand,
  showState?: boolean,
}

const DemandCard = ({ useCard, user, demand, showState }: DemandCardProps) => {

  const theme = useTheme();
  const matchMedia = useMediaQuery(theme.breakpoints.down('md'))

  let labelChip = '';
  switch (demand?.product?.state) {
    case 'CLOSED':
      labelChip = 'Chiuso';
      break;
  }

  const content = (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <ResponsiveGridItem>
        <Stack direction={matchMedia ? 'column' : 'row'} spacing={1}>
          {showState && <Chip color={demand?.product?.approved ? 'success' : 'error'}
            label={demand?.product?.approved ? 'Approvato' : 'Non approvato'} />}
          {labelChip && <Chip color={'error'} label={labelChip} />}
          <Typography variant="h6">{demand?.product?.name}</Typography>
        </Stack>
        <Typography
          variant="subtitle1">{StringGeneratorUtil.generateConsumerLabel({ ...demand?.product?.user?.researchConsumer!, user: demand?.product?.user })}</Typography>
        {(user?.role === Roles[2].id || user?.role === Roles[3].id) && <Typography variant="subtitle1">Numero di utenti interessati: {demand?.userLikes?.filter((value: any) => value.state === 'INTERESTED').length}</Typography>}
        <Typography variant="body2" textAlign="justify" sx={{ whiteSpace: 'pre-line' }}>{demand?.product?.description?.length! > 200 ? demand?.product?.description?.substring(0, 200) + "..." : (demand?.product?.description ?? '')}</Typography>
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        <KeywordsBadges keywords={demand?.product?.keywords!} matchMedia={matchMedia} />
      </ResponsiveGridItem>
    </Grid>
  );

  return (
    useCard ? <Card sx={{
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText'
      }
    }}>
      <CardContent>
        {content}
      </CardContent>
    </Card> : content
  );
}

export default DemandCard;
