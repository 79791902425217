import { CancelPresentation, CheckCircle, Delete, Edit, ListAlt, ThumbUpAlt, ThumbUpOffAlt, Unpublished, VerticalAlignTop, Visibility } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import MenuButton from "../../../components/common/MenuButton";
import MenuButtonItem from "../../../components/common/MenuButtonItem";
import DemandCard from "../../../components/common/table/DemandCard";
import LoggedUser from "../../auth/LoggedUser";
import { CommonColumnProps } from "../CommonColumnProps";

interface ProductsTableColumnsProps {
    loggedUser?: LoggedUser,
    showCallback?: (id: string) => any,
    editCallback?: (id: string) => any,
    deleteCallback?: (id: string) => any,
    approveCallback?: (id: string) => any,
    closeCallback?: (id: string) => any,
    thresholdCallback?: (id: string) => any,
    disapproveCallback?: (id: string) => any,
    showInterestedCallback?: (id: string) => any,
    interestCallback?: (id: string) => any,
    removeInterestCallback?: (id: string) => any,
    showApproved?: boolean
}

const DemandsTableColumns = ({
    loggedUser,
    showCallback,
    editCallback,
    deleteCallback,
    closeCallback,
    approveCallback,
    disapproveCallback,
    showInterestedCallback,
    interestCallback,
    thresholdCallback,
    removeInterestCallback,
    showApproved
}: ProductsTableColumnsProps): GridColDef[] => [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
            ...CommonColumnProps
        },
        {
            field: 'data',
            headerName: "Fabbisogno",
            renderCell: (params) => {
                return (
                    <Box my={2} mx={1} width="100%">
                        <DemandCard key={params.row.id} demand={params.row.data} user={loggedUser}
                            showState={(showApproved ?? false) && (loggedUser?.id === params.row.data.product.user.id || loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT')} />
                    </Box>
                )
            },
            ...CommonColumnProps,
            minWidth: 100,
            align: 'center',
            flex: 10,
        },
        {
            field: "action",
            headerName: "Operazioni",
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => {
                let menuButtonItems: MenuButtonItem[] = [
                    {
                        icon: <Visibility color="secondary" />,
                        text: "Visualizza",
                        handleClick: showCallback
                    }
                ];
                if (loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT')) {
                    menuButtonItems.push(
                        {
                            icon: <Edit color="secondary" />,
                            text: "Modifica",
                            handleClick: editCallback
                        },
                        {
                            icon: <ListAlt color="secondary" />,
                            disabled: params.row.data.userLikes.filter((value: any) => value.state === 'INTERESTED').length === 0,
                            text: 'Visualizza interessati',
                            handleClick: showInterestedCallback
                        },
                        {
                            icon: params.row.approved ? <Unpublished color="secondary" /> :
                                <CheckCircle color="secondary" />,
                            text: params.row.approved ? 'Disapprova' : 'Approva',
                            handleClick: params.row.approved ? disapproveCallback : approveCallback
                        },
                        {
                            icon: <VerticalAlignTop color="secondary"/>,
                            disabled: !params.row.approved || params.row.state === 'CLOSED',
                            text: 'Imposta threshold',
                            handleClick: thresholdCallback
                        },
                        {
                            icon: <CancelPresentation color="secondary"/>,
                            disabled: !params.row.approved || params.row.state === 'CLOSED',
                            text: 'Chiudi',
                            handleClick: closeCallback
                        },
                        {
                            icon: <Delete color="error" />,
                            text: 'Elimina',
                            handleClick: deleteCallback
                        },
                    );
                } else if (loggedUser && loggedUser.role === 'RESEARCH_CONSUMER' && loggedUser?.id === params.row.data.product.user.id) {
                    menuButtonItems.push(
                        {
                            icon: <Edit color="secondary" />,
                            text: "Modifica",
                            handleClick: editCallback
                        },
                        {
                            icon: <Delete color="error" />,
                            text: 'Elimina',
                            handleClick: deleteCallback
                        },
                    );
                } else if (loggedUser && (loggedUser.role === 'RESEARCH_PRODUCER' || loggedUser.role === 'RESEARCH_CONSUMER')) {
                    menuButtonItems = [
                        {
                            icon: <Visibility color="secondary" />,
                            text: "Visualizza",
                            handleClick: showCallback
                        },
                        {
                            icon: params.row.userInterest ? <ThumbUpAlt color="secondary" /> : <ThumbUpOffAlt color="secondary" />,
                            disabled: !params.row.approved || params.row.state === 'CLOSED',
                            text: params.row.userInterest ? 'Rimuovi interesse' : 'Esprimi interesse',
                            handleClick: params.row.userInterest ? removeInterestCallback : interestCallback
                        },
                    ];
                }

                return <Stack direction="row" spacing={0} alignItems="flex-end">
                    {menuButtonItems.length === 0 ? <Tooltip title="Visualizza">
                        <IconButton color="secondary" onClick={() => showCallback ? showCallback(params.id as string) : ''}>
                            <Visibility />
                        </IconButton>
                    </Tooltip> :
                        <MenuButton
                            rowId={params.id as string}
                            title="Azioni"
                            children={menuButtonItems}
                        />}
                </Stack>;
            },
            ...CommonColumnProps
        },
    ];

export default DemandsTableColumns;
