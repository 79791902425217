import { HttpStatusCode } from "axios";
import LoggedUserContext from "../model/auth/LoggedUserContext";
import PageInfoRequest from "../model/common/PageInfoRequest";
import DemandDTO from "../model/dto/DemandDTO";
import CallsFilters from "../model/filters/CallsFilters";
import http from '../utils/HttpCommon';

const getAll = async (context: LoggedUserContext, pageInfo: PageInfoRequest) => {
	try {
		const response = await http.privateClient(context).get<any>('/demands/search/findAllNotDeleted', { params: pageInfo });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const getAllFiltered = async (context: LoggedUserContext, pageInfo: PageInfoRequest, filters: CallsFilters) => {
	try {
		let params: any = {
			...pageInfo
		};
		Object.entries(filters).forEach(([key, lblValue]) => {
			params[key] = lblValue.value;
		});

		const response = await http.privateClient(context).get<any>('/demands/search/findAllByFilters', { params });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const save = async (context: LoggedUserContext, demand: DemandDTO) => {
	try {
		const response = await http.privateClient(context).post<any>('/demands', { ...demand });

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const update = async (context: LoggedUserContext, demand: DemandDTO, id: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/demands/${id}`, demand);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteById = async (context: LoggedUserContext, id: string) => {
	try {
		const response = await http.privateClient(context).delete<any>(`/demands/${id}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const changeStateById = async (context: LoggedUserContext, id: string, state: string) => {
	try {
		const response = await http.privateClient(context).put<any>(`/demands/${id}/state/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const changeInterestById = async (context: LoggedUserContext, id: string, state: boolean) => {
	try {
		const response = await http.privateClient(context).put<any>(`/demands/${id}/user/${context.user?.id}/interest/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const updateThresholdById = async (context: LoggedUserContext, id: string, threshold: number) => {
	try {
		const response = await http.privateClient(context).put<any>(`/demands/${id}/threshold/${threshold}`);

		if (response && response.status === HttpStatusCode.Ok) {
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const changeApprovedById = async (context: LoggedUserContext, id: string, state: boolean) => {
	try {
		const response = await http.privateClient(context).put<any>(`/demands/${id}/approved/${state}`);

		if (response && response.status === HttpStatusCode.Ok) {
			context.setNeedRefreshSidebar?.(true);
			return [response.status, response.data];
		}

		return [response ? response.status : undefined, undefined];

	} catch (e: any) {
		console.error(e);
		return [e.response ? e.response.status : undefined, undefined];
	}
}

const deleteAll = async (context: LoggedUserContext) => { }
const exportSelected = async (context: LoggedUserContext) => { }

const DemandService = {
	getAll,
	getAllFiltered,
	save,
	update,
	changeStateById,
	deleteById,
	updateThresholdById,
	changeInterestById,
	changeApprovedById,
	deleteAll,
	exportSelected
}

export default DemandService;