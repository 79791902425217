const generateUniqueHexColors = (n: number): string[] => {
    const generatedColors = new Set<string>();
    for (let i = 0; i < n; i++) {
        let color: string;
        do {
            color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
        } while (generatedColors.has(color));
        generatedColors.add(color);
    }
    return Array.from(generatedColors);
};

export default {
    generateUniqueHexColors
}