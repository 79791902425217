import { Box } from "@mui/material";
import { HttpStatusCode } from "axios";
import { FormikValues } from "formik";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { SweetAlertOptions } from 'sweetalert2';
import ResearchConsumerCard from "../../components/admin/account/ResearchConsumerCard";
import ResearchProducerCard from "../../components/admin/account/ResearchProducerCard";
import UserCard from "../../components/admin/account/UserCard";
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';
import AdminLayout from "../../layouts/admin/AdminLayout";
import ResearchInstitution from "../../model/database/ResearchInstitution";
import User from "../../model/database/User";
import AuthContext from '../../route/AuthContext';
import UserService from "../../services/UserService";
import CustomSweetalert from "../../theme/sweetalert";

const Account = () => {
    const authContext = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<User>();
    const [institutions, setInstitutions] = React.useState<ResearchInstitution[]>([]);
    const location = useLocation();

    const contentBreadcrumb = BreadcrumbGenerator.generateContent(authContext?.user?.role ?? '', location.pathname);

    const loadResearchInstitution = async () => {
        const [status, response] = await UserService.getAllResearchInstitutions( undefined, {page: 0, size: -1, sort: 'id,asc'});
        setInstitutions(response ? response._embedded.researchInstitutions : []);
    }

    const reloadData = async () => {
        setLoading(true);
        let [status, response] = [undefined, undefined];
        [status, response] = await UserService.getById(authContext, authContext?.user?.id ? authContext?.user?.id : '');
        
    
        if (status && response && status === HttpStatusCode.Ok) {
          const userTmp: User = {
            ...(response as any),
            researchConsumer: {
              name:(response as any).researchConsumer?.businessName ?? '',
              ...(response as any).researchConsumer
            }
        };
          setUser(userTmp);
        } else {
          CustomSweetalert({
            title: 'Attenzione',
            text: 'Si è verificato un\'errore durante la comunicazione con il server remoto.',
            icon: 'error'
          });
        }

        if(authContext?.user?.role === 'RESEARCH_PRODUCER'){
            await loadResearchInstitution();
        }
    
        setLoading(false);
      };
    
      React.useEffect(() => {
        reloadData();
      }, []);

    // const handleSave = (userToSave: FormikValues) => {
    //     return new Promise<boolean>(resolve => {
    //         setTimeout(() => {
    //             resolve(true);
    //             Swal.fire({
    //                 title: 'Completato',
    //                 text: 'Profilo utente aggiornato correttamente',
    //                 icon: 'success',
    //             })
    //         }, 2000);
    //     });
    // }
    const handleSaveUser = (userToSave: FormikValues) => {
        return new Promise<boolean>(async resolve => {
          let [status, response] = [undefined, undefined];
          if(userToSave)
            [status, response] = await UserService.update(authContext, userToSave, authContext?.user?.id ? authContext?.user?.id : '');
          
    
          if (status && response && status === HttpStatusCode.Ok) {
            let swOptions: SweetAlertOptions = {
              title: 'Completato',
              text: 'Utente aggiunto correttamente.',
              icon: 'success'
            };
    
            switch (response) {
              case 'UPDATED':
                resolve(true);
                swOptions.text = 'Utente aggiornato correttamente.';
                break;
              default:
                resolve(false);
                swOptions.title = 'Errore';
                swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                swOptions.icon = 'error';
                break;
            }
            CustomSweetalert(swOptions).then(() => {
              reloadData();
            });
          } else{
            CustomSweetalert({
              title: 'Attenzione',
              text: 'Non è stato possibile salvare l\'utente selezionato.',
              icon: 'error'
            })
            resolve(false);
          } 
          
        })
      }
    
      const handleSaveResearchProducer = (userToSave: FormikValues) => {
        
        return new Promise<boolean>(async resolve => {
          let [status, response] = [undefined, undefined];
          if(userToSave)
            [status, response] = await UserService.updateResearchProducer(authContext, userToSave, authContext?.user?.id ? authContext?.user?.id : '');
          
    
          if (status && response && status === HttpStatusCode.Ok) {
            let swOptions: SweetAlertOptions = {
              title: 'Completato',
              text: 'Utente aggiornato correttamente.',
              icon: 'success'
            };
    
            switch (response) {
              case 'UPDATED':
                resolve(true);
                break;
              default:
                resolve(false);
                swOptions.title = 'Errore';
                swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                swOptions.icon = 'error';
                break;
            }
            CustomSweetalert(swOptions).then(() => {
              reloadData();
            });
          } else{
            CustomSweetalert({
              title: 'Attenzione',
              text: 'Non è stato possibile salvare l\'utente selezionato.',
              icon: 'error'
            })
            resolve(false);
          } 
        })
      }
    
      const handleSaveResearchConsumer = (userToSave: FormikValues) => {
        return new Promise<boolean>(async resolve => {
          let [status, response] = [undefined, undefined];

          if(userToSave)
            [status, response] = await UserService.updateResearchConsumer(authContext, userToSave, authContext?.user?.id ? authContext?.user?.id : '');
    
          if (status && response && status === HttpStatusCode.Ok) {
            let swOptions: SweetAlertOptions = {
              title: 'Completato',
              text: 'Utente aggiornato correttamente.',
              icon: 'success'
            };
    
            switch (response) {
              case 'UPDATED':
                resolve(true);
                break;
              default:
                resolve(false);
                swOptions.title = 'Errore';
                swOptions.text = 'Si è verificato un problema durante il salvataggio.';
                swOptions.icon = 'error';
                break;
            }
            CustomSweetalert(swOptions).then(() => {
              reloadData();
            });
          } else{
            CustomSweetalert({
              title: 'Attenzione',
              text: 'Non è stato possibile salvare l\'utente selezionato.',
              icon: 'error'
            })
            resolve(false);
          } 
        })
      }

    return (
        <AdminLayout
            title="Profilo utente | Piattaforma Innovation ReSearch"
            breadcrumb={contentBreadcrumb}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 1
            }}>
                {['COMMITTEE_MANAGEMENT','ADMINISTRATOR', 'EXTERNAL_AGENCY'].includes(authContext?.user?.role ?? '') && <UserCard handleSave={handleSaveUser} user={user} />}
                {authContext?.user?.role === 'RESEARCH_CONSUMER' && <ResearchConsumerCard handleSave={handleSaveResearchConsumer} user={user} />}
                {authContext?.user?.role === 'RESEARCH_PRODUCER' && <ResearchProducerCard handleSave={handleSaveResearchProducer} user={user} institutions={institutions} />}
            </Box>
        </AdminLayout>
    );
};

export default Account;
