import { Grid } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import DemandsBarChart from '../../components/admin/dashboard/DemandsBarChart';
import DemandsLikePerYear from '../../components/admin/dashboard/DemandsLikePerYear';
import DemandsPieChart from '../../components/admin/dashboard/DemandsPieChart';
import BreadcrumbGenerator from '../../components/common/BreadcrumbGenerator';
import AdminLayout from "../../layouts/admin/AdminLayout";
import AuthContext from '../../route/AuthContext';

const DemandStatistics = () => {

    const authContext = React.useContext(AuthContext);

    const location = useLocation();
    const contentBreadcrumb = BreadcrumbGenerator.generateContent(authContext.user?.role ?? '', location.pathname);

    return (
        <AdminLayout
            breadcrumb={contentBreadcrumb}
            title="Statistiche | Piattaforma Innovation ReSearch"
        >
            <Grid container spacing={2} mt={1} mb={4} alignItems="center">
                <Grid item md={6} xs={12}>
                    <DemandsBarChart
                        title="Top 10 fabbisogni da evadere"
                        state='ACTIVE'
                        topK={10}
                        secondary={false}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <DemandsBarChart
                        title="Top 10 fabbisogni evasi"
                        state='CLOSED'
                        topK={10}
                        secondary={true}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <DemandsPieChart
                        title="Distribuzione parole chiave per fabbisogno"
                    />
                </Grid>
                <Grid item md={8} xs={12}>
                    <DemandsLikePerYear
                        title="Numero di like per fabbisogno nell'anno"
                    />
                </Grid>
            </Grid>
        </AdminLayout>
    );
};

export default DemandStatistics;
