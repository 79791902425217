import { CheckCircle, ContentCopy, Delete, Edit, Unpublished, Visibility } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import MenuButton from "../../../components/common/MenuButton";
import MenuButtonItem from "../../../components/common/MenuButtonItem";
import ServiceCard from "../../../components/common/table/ServiceCard";
import i18n from "../../../i18n/i18n";
import LoggedUser from "../../auth/LoggedUser";
import { CommonColumnProps } from "../CommonColumnProps";

interface ServicesTableColumnsProps {
    loggedUser?: LoggedUser,
    showCallback?: (id: string) => any,
    editCallback?: (id: string) => any,
    cloneCallback?: (id: string) => any,
    deleteCallback?: (id: string) => any,
    approveCallback?: (id: string) => any,
    disapproveCallback?: (id: string) => any,
    showApproved?: boolean
}

const ServicesTableColumns = ({
                                  loggedUser,
                                  showCallback,
                                  editCallback,
                                  cloneCallback,
                                  deleteCallback,
                                  approveCallback,
                                  disapproveCallback,
                                  showApproved
                              }: ServicesTableColumnsProps): GridColDef[] => [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
        ...CommonColumnProps
    },
    {
        field: 'data',
        headerName: 'Evento',
        renderCell: (params) => (
            <Box my={2} mx={1} width="100%">
                <ServiceCard key={params.row.id} researchService={params.row.data}
                             showState={(showApproved ?? false) && (loggedUser?.id === params.row.data.producer.user.id || (params.row.data.producer && loggedUser?.idParent === params.row.data.producer.user.id) || loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT')}/>
            </Box>
        ),
        ...CommonColumnProps,
        minWidth: 100,
        align: 'center',
        flex: 10,
    },
    {
        field: "action",
        headerName: i18n.t('table.operations') as string,
        hide: !showCallback && !deleteCallback,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {
            
            let menuButtonItems: MenuButtonItem[] = [];

            if (loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT')) {
                menuButtonItems = [
                    {
                        icon: <Visibility color="secondary" />,
                        text: "Visualizza",
                        handleClick: showCallback
                    },
                    {
                        icon: <Edit color="secondary" />,
                        text: 'Modifica',
                        handleClick: editCallback
                    },
                    {
                        icon: params.row.approved ? <Unpublished color="secondary" /> :
                            <CheckCircle color="secondary" />,
                        text: params.row.approved ? 'Disapprova' : 'Approva',
                        handleClick: params.row.approved ? disapproveCallback : approveCallback
                    },
                    {
                        icon: <ContentCopy color="secondary" />,
                        text: 'Duplica',
                        handleClick: cloneCallback
                    },
                    {
                        icon: <Delete color="error" />,
                        text: 'Elimina',
                        handleClick: deleteCallback
                    },
                ];
            } else if (loggedUser && loggedUser.role === 'RESEARCH_PRODUCER' && (loggedUser?.id === params.row.data.producer.user.id || (params.row.data.producer && loggedUser?.idParent === params.row.data.producer.user.id))) {
                menuButtonItems = [
                    {
                        icon: <Visibility color="secondary" />,
                        text: "Visualizza",
                        handleClick: showCallback
                    },
                    {
                        icon: <Edit color="secondary" />,
                        text: 'Modifica',
                        handleClick: editCallback
                    },
                    {
                        icon: <ContentCopy color="secondary" />,
                        text: 'Duplica',
                        handleClick: cloneCallback
                    },
                    {
                        icon: <Delete color="error" />,
                        text: 'Elimina',
                        handleClick: deleteCallback
                    },
                ];
            } else if (loggedUser && loggedUser.role === 'RESEARCH_PRODUCER') {
                menuButtonItems = [
                    {
                        icon: <Visibility color="secondary" />,
                        text: "Visualizza",
                        handleClick: showCallback
                    },
                    {
                        icon: <ContentCopy color="secondary" />,
                        text: 'Duplica',
                        handleClick: cloneCallback
                    }
                ];
            }

            return <Stack direction="row" spacing={0} alignItems="flex-end">
                {menuButtonItems.length === 0 ? <Tooltip title="Visualizza">
                    <IconButton color="secondary" onClick={() => showCallback ? showCallback(params.id as string) : ''}>
                        <Visibility />
                    </IconButton>
                </Tooltip> :
                    <MenuButton
                        rowId={params.id as string}
                        title="Azioni"
                        children={menuButtonItems}
                    />}
            </Stack>;
        },
        ...CommonColumnProps
    }
];

export default ServicesTableColumns;
