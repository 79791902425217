import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { Formik, FormikValues } from "formik";
import React from "react";
import * as Yup from "yup";
import User from "../../../model/database/User";
import CircularLoading from "../../common/CircularLoading";
import CustomDialog from "../../common/CustomDialog";
import ResponsiveGridItem from "../../common/ResponsiveGridItem";

interface UserModalProps {
  open: boolean,
  editEnable?: boolean,
  user?: User,
  handleClose: () => void,
  handleSave: (userToSave: FormikValues, edit: boolean) => Promise<boolean>
}

const UserModal = ({ open, editEnable, user, handleSave, handleClose }: UserModalProps) => {

  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('Crea nuovo utente');

  React.useEffect(() => {
    if (editMode && user)
      setTitle('Modifica utente');
    else if (user)
      setTitle('Dettagli utente');
    else
      setTitle('Crea nuovo utente');
  }, [editMode, user]);

  const resetAndClose = () => {
    setEditMode(false);
    handleClose();
  }

  return <Formik
    enableReinitialize
    initialValues={{
      name: user ? user.name : '',
      surname: user ? user.surname : '',
      username: user ? user.username : '',
      email: user ? user.email : '',
      role: user ? user.role?.id : '',
      state: user ? user.state : 'INACTIVE',
      password: '',
      confirmPassword: ''
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('Il nome è obbligatorio e non può essere vuoto.'),
      surname: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('Il cognome è obbligatorio e non può essere vuoto.'),
      username: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('Lo username è obbligatorio e non può essere vuoto.'),
      email: Yup.string().max(255).email('Il valore inserito non è un formato corretto.')
        .required('L\'email è obbligatoria e non può essere vuota.'),
      role: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('La tipologia è obbligatoria e non può essere vuota.'),
      state: Yup.string().max(255, 'Il valore massimo consentito è di 255 caratteri')
        .required('Lo stato è obbligatorio e non può essere vuota.'),
      password: user ? Yup.string().min(8, 'La password deve essere di almeno 8 caratteri')
        .max(255, 'Il valore massimo consentito è di 255 caratteri')
         : Yup.string().min(8, 'La password deve essere di almeno 8 caratteri')
          .max(255, 'Il valore massimo consentito è di 255 caratteri')
          .required('La password è obbligatoria e non può essere vuota'),
      confirmPassword: user ? Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono corrispondere') :
        Yup.string().oneOf([Yup.ref('password'), null], 'Le password devono corrispondere')
          .required('La conferma della password è obbligatoria e non può essere vuota.')
    })}
    onSubmit={async (values, { resetForm }) => {
      let result = await handleSave(values, editMode);
      if (result) {
        resetForm();
        setEditMode(false);
      }
    }}
  >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      touched,
      submitForm,
      values
    }) => (
      <CustomDialog
        open={open ? open : false}
        handleClose={() => {
          if (!isSubmitting) resetAndClose()
        }}
        title={title}
        content={
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  inputProps={{
                    readOnly: !editMode && user !== undefined
                  }}
                  disabled={isSubmitting || (editMode && user !== undefined)}
                  value={values.username}
                  required
                  variant="outlined"
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  inputProps={{
                    readOnly: !editMode && user !== undefined
                  }}
                  disabled={isSubmitting}
                  value={values.email}
                  required
                  variant="outlined"
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Nome"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  inputProps={{
                    readOnly: !editMode && user !== undefined
                  }}
                  disabled={isSubmitting}
                  value={values.name}
                  required
                  variant="outlined"
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.surname && errors.surname)}
                  fullWidth
                  helperText={touched.surname && errors.surname}
                  label="Cognome"
                  margin="normal"
                  name="surname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  inputProps={{
                    readOnly: !editMode && user !== undefined
                  }}
                  disabled={isSubmitting}
                  value={values.surname}
                  required
                  variant="outlined"
                />
              </ResponsiveGridItem>
              <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.role && errors.role)}
                  fullWidth
                  helperText={touched.role && errors.role}
                  label="Ruolo"
                  margin="normal"
                  name="role"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{
                    readOnly: !editMode && user !== undefined
                  }}
                  select
                  disabled={isSubmitting || (editMode && user !== undefined)}
                  value={values.role}
                  required
                  variant="outlined"
                >
                  <MenuItem value="ADMINISTRATOR">Amministratore di sistema</MenuItem>
                  <MenuItem value="COMMITTEE_MANAGEMENT">Comitato di indirizzo e gestione</MenuItem>
                  <MenuItem value="EXTERNAL_AGENCY">Agenzia esterna</MenuItem>
                </TextField>
              </ResponsiveGridItem>
              <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.state && errors.state)}
                  fullWidth
                  helperText={touched.state && errors.state}
                  label="Stato"
                  margin="normal"
                  name="state"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  inputProps={{
                    readOnly: !editMode && user !== undefined
                  }}
                  disabled={isSubmitting}
                  value={values.state}
                  required
                  variant="outlined"
                >
                  <MenuItem value="INACTIVE">Non attivo</MenuItem>
                  <MenuItem value="ACTIVE">Attivo</MenuItem>
                </TextField>
              </ResponsiveGridItem>
              {(editMode || !user) && <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  disabled={isSubmitting}
                  value={values.password}
                  required={!user}
                  variant="outlined"
                />
              </ResponsiveGridItem>}
              {(editMode || !user) && <ResponsiveGridItem md={6}>
                <TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Conferma password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  disabled={isSubmitting}
                  value={values.confirmPassword}
                  required={!user}
                  variant="outlined"
                />
              </ResponsiveGridItem>}
            </Grid>
          </form>
        }
        actions={
          <>
            <Button color="inherit" disabled={isSubmitting} onClick={resetAndClose}>
              Chiudi
            </Button>
            {editEnable && (!editMode && user !== undefined ? <Button variant="contained" onClick={() => setEditMode(true)} autoFocus>
              Modifica
            </Button> : <Button variant="contained" disabled={isSubmitting} onClick={submitForm} autoFocus>
              Conferma
              {isSubmitting && <CircularLoading />}
            </Button>)}
          </>
        }
      />
    )}
  </Formik>;
}

export default UserModal;
