import {
    CancelPresentation,
    CheckCircle,
    Delete,
    ListAlt,
    ThumbUpAlt,
    ThumbUpOffAlt,
    Unpublished,
    Visibility
} from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import MenuButton from "../../../components/common/MenuButton";
import MenuButtonItem from "../../../components/common/MenuButtonItem";
import CallCard from "../../../components/common/table/CallCard";
import LoggedUser from "../../auth/LoggedUser";
import { CommonColumnProps } from "../CommonColumnProps";

interface CallsTableColumnsProps {
    loggedUser?: LoggedUser,
    showCallback?: (id: string) => any,
    interestCallback?: (id: string) => any,
    removeInterestCallback?: (id: string) => any,
    showInterestedCallback?: (id: string) => any,
    closeCallback?: (id: string) => any,
    deleteCallback?: (id: string) => any,
    approveCallback?: (id: string) => any,
    disapproveCallback?: (id: string) => any,
}

const CallsTableColumns = ({
                               loggedUser,
                               showCallback,
                               closeCallback,
                               deleteCallback,
                               approveCallback,
                               disapproveCallback,
                               removeInterestCallback,
                               interestCallback,
                               showInterestedCallback
                           }: CallsTableColumnsProps): GridColDef[] => [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
        ...CommonColumnProps
    },
    {
        field: 'data',
        headerName: 'Bandi',
        renderCell: (params) => (
            <Box my={2} mx={1} width="100%">
                <CallCard call={params.row.data} state={params.row.state} user={loggedUser}
                          showState={loggedUser?.id === params.row.data?.product?.user?.id || loggedUser?.role === 'ADMINISTRATOR' || loggedUser?.role === 'COMMITTEE_MANAGEMENT'}/>
            </Box>
        ),
        ...CommonColumnProps,
        minWidth: 100,
        align: 'center',
        flex: 10,
    },
    {
        field: "action",
        headerName: "Operazioni",
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) => {

            let menuButtonItems: MenuButtonItem[] = [];

                if (loggedUser && (loggedUser.role === 'ADMINISTRATOR' || loggedUser.role === 'COMMITTEE_MANAGEMENT')) {
                    menuButtonItems = [
                        {
                            icon: <Visibility color="secondary" />,
                            text: "Visualizza",
                            handleClick: showCallback
                        },
                        {
                            icon: <ListAlt color="secondary" />,
                            disabled: params.row.data.userCalls.filter((value: any) => value.state === 'INTERESTED').length === 0,
                            text: 'Visualizza interessati',
                            handleClick: showInterestedCallback
                        },
                        {
                            icon: params.row.approved ? <Unpublished color="secondary" /> :
                                <CheckCircle color="secondary" />,
                            text: params.row.approved ? 'Disapprova' : 'Approva',
                            handleClick: params.row.approved ? disapproveCallback : approveCallback
                        },
                        {
                            icon: <CancelPresentation color="secondary"/>,
                            disabled: params.row.state.toUpperCase() === 'CLOSED' || params.row.state.toUpperCase() === 'EXPIRED',
                            text: 'Chiudi',
                            handleClick: closeCallback
                        },
                        {
                            icon: <Delete color="error"/>,
                            text: 'Elimina',
                            handleClick: deleteCallback
                        },
                    ];
                } else if (loggedUser && (loggedUser.role === 'RESEARCH_PRODUCER' || loggedUser.role === 'RESEARCH_CONSUMER')) {
                    menuButtonItems = [
                        {
                            icon: <Visibility color="secondary" />,
                            text: "Visualizza",
                            handleClick: showCallback
                        },
                        {
                            icon: params.row.userInterest ? <ThumbUpAlt color="secondary" /> : <ThumbUpOffAlt color="secondary" />,
                            disabled: params.row.state === 'INACTIVE' || params.row.state === 'EXPIRED' || params.row.state === 'CLOSED',
                            text: params.row.userInterest ? 'Rimuovi interesse' : 'Esprimi interesse',
                            handleClick: params.row.userInterest ? removeInterestCallback : interestCallback
                        },
                    ];
                }

                return <Stack direction="row" spacing={0} alignItems="flex-end">
                    {menuButtonItems.length === 0 ? <Tooltip title="Visualizza">
                        <IconButton color="secondary" onClick={() => showCallback ? showCallback(params.id as string) : ''}>
                            <Visibility />
                        </IconButton>
                    </Tooltip> :
                        <MenuButton
                            rowId={params.id as string}
                            title="Azioni"
                            children={menuButtonItems}
                        />}
                </Stack>;
        },
        ...CommonColumnProps
    },
];

export default CallsTableColumns;
